/*
  Template Name: CCA - Education & Courses Landing Page Template
  Theme URL: https://themeforest.net/user/jthemes
  Description: CCA - Education & Courses Landing Page Template
  Author: Jthemes
  Author URL: https://themeforest.net/user/jthemes
  Version: 1.0.0
  Tags: Responsive, HTML5 Template, Jthemes, Courses, Education, Learning, Online Education, Study
*/

/* ==========================================================================
  01. GENERAL & BASIC STYLES
  =========================================================================== */

html {
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

body {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #283034;
  font-weight: 300;
}

/*------------------------------------------------------------------*/
/*  IE10 in Windows 8 and Windows Phone 8 Bug fix
/*-----------------------------------------------------------------*/

@-webkit-viewport {
  width: device-width;
}
@-moz-viewport {
  width: device-width;
}
@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}

/*------------------------------------------*/
/*  PAGE CONTENT
/*------------------------------------------*/

#page {
  overflow: hidden;
}

/*------------------------------------------*/
/*  SPACING & INDENTS
/*------------------------------------------*/

.wide-100 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.wide-90 {
  padding-top: 10px;
  padding-bottom: 9px;
}
.wide-80 {
  padding-top: 10px;
  padding-bottom: 8px;
}
.wide-70 {
  padding-top: 10px;
  padding-bottom: 7px;
}
.wide-60 {
  padding-top: 10px;
  padding-bottom: 6px;
}
.wide-50 {
  padding-top: 10px;
  padding-bottom: 5px;
}
.wide-40 {
  padding-top: 10px;
  padding-bottom: 4px;
}
.wide-30 {
  padding-top: 10px;
  padding-bottom: 3px;
}
.wide-20 {
  padding-top: 30px;
  padding-bottom: 2px;
}

/*------------------------------------------*/
/*  Margin Top
/*------------------------------------------*/

.mt-100 {
  margin-top: 100px;
}
.mt-95 {
  margin-top: 95px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-85 {
  margin-top: 85px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-75 {
  margin-top: 75px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-65 {
  margin-top: 65px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-55 {
  margin-top: 55px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-5 {
  margin-top: 5px;
}

/*------------------------------------------*/
/*  Margin Bottom
/*------------------------------------------*/

.mb-100 {
  margin-bottom: 100px;
}
.mb-95 {
  margin-bottom: 95px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-85 {
  margin-bottom: 85px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-75 {
  margin-bottom: 75px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-65 {
  margin-bottom: 65px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-55 {
  margin-bottom: 55px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-0 {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Margin Left
/*------------------------------------------*/

.ml-70 {
  margin-left: 70px;
}
.ml-60 {
  margin-left: 60px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-45 {
  margin-left: 45px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-35 {
  margin-left: 35px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-5 {
  margin-left: 5px;
}

/*------------------------------------------*/
/*  Margin Right
/*------------------------------------------*/

.mr-70 {
  margin-right: 70px;
}
.mr-60 {
  margin-right: 60px;
}
.mr-50 {
  margin-right: 50px;
}
.mr-45 {
  margin-right: 45px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-35 {
  margin-right: 35px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-25 {
  margin-right: 25px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-5 {
  margin-right: 5px;
}

/*------------------------------------------*/
/*  Padding Top
/*------------------------------------------*/

.pt-100 {
  padding-top: 100px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-10 {
  padding-top: 10px;
}

/*------------------------------------------*/
/*  Padding Bottom
/*------------------------------------------*/

.pb-100 {
  padding-bottom: 100px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-0 {
  padding-bottom: 0;
}

/*------------------------------------------*/
/*  Padding Left
/*------------------------------------------*/

.pl-100 {
  padding-left: 100px;
}
.pl-90 {
  padding-left: 90px;
}
.pl-80 {
  padding-left: 80px;
}
.pl-70 {
  padding-left: 70px;
}
.pl-60 {
  padding-left: 60px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-0 {
  padding-left: 0px;
}

/*------------------------------------------*/
/*  Padding Right
/*------------------------------------------*/

.pr-100 {
  padding-right: 100px;
}
.pr-90 {
  padding-right: 90px;
}
.pr-80 {
  padding-right: 80px;
}
.pr-70 {
  padding-right: 70px;
}
.pr-60 {
  padding-right: 60px;
}
.pr-50 {
  padding-right: 50px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-0 {
  padding-right: 0px;
}

/*------------------------------------------*/
/*  Padding Centered
/*------------------------------------------*/

.pc-45 {
  padding-right: 45px;
  padding-left: 45px;
}
.pc-40 {
  padding-right: 40px;
  padding-left: 40px;
}
.pc-35 {
  padding-right: 35px;
  padding-left: 35px;
}
.pc-30 {
  padding-right: 30px;
  padding-left: 30px;
}
.pc-25 {
  padding-right: 25px;
  padding-left: 25px;
}
.pc-20 {
  padding-right: 20px;
  padding-left: 20px;
}
.pc-15 {
  padding-right: 15px;
  padding-left: 15px;
}

/*------------------------------------------*/
/*  BACKGROUND SETTINGS
/*------------------------------------------*/

.bg-fixed,
.bg-scroll {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bg-scroll {
  background-attachment: fixed !important;
}

/*------------------------------------------*/
/*  Background Colors
/*------------------------------------------*/
/*
background-color: #f3f6f7;*/

.bg-lightdark {
  background-color: #283034;
}
.bg-dark {
  background-color: #1d1e29 !important;
}
.bg-lightgrey {
  background-color: #f8f9fb;
}
.bg-whitesmoke {
  background-color: #f2f5f6;
}
.bg-carrot {
  background-color: #d05a24;
}
.bg-blue {
  background-color: #3284bf;
}
.bg-darkblue {
  background-color: #253993;
}
.bg-skyblue {
  background-color: #1ba1f0;
}
.bg-green {
  background-color: #20ab5c;
}
.bg-lightgreen {
  background-color: #8dd17e;
}
.bg-red {
  background-color: #ff6d33;
}
.bg-rose {
  background-color: #ff1f59;
}
.bg-olive {
  background-color: #7ba323;
}
.bg-orange {
  background-color: #ff4500;
}
.bg-teal {
  background-color: #109d87;
}
.bg-yellow {
  background-color: #ffb605;
}
.bg-violet {
  background-color: #8a8cbd;
}

/*------------------------------------------*/
/*  Background Images for Sections
/*------------------------------------------*/

.bg-01,
.bg-02,
.bg-03,
.bg-04,
.bg-05,
.bg-06,
.bg-map {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed !important;
}

.bg-01 {
  background-image: url(../../public/images/bg-01.jpg);
}

.bg-map {
  background-image: url(../../public/images/bg-map.png);
}
.bg-home-banner{
  width: 100%;
  height: 380px;
  background-image: url(../../public/images/home-banner.jpg);
}
.bg-02 {
  background-image: url(../../public/images/bg-02.jpg);
}

.bg-03 {
  background-image: url(../../public/images/bg-03.jpg);
}

.bg-04 {
  background-image: url(../../public/images/bg-04.jpg);
}

.bg-05 {
  background-image: url(../../public/images/bg-05.jpg);
}

.bg-06 {
  background-image: url(../../public/images/bg-06.jpg);
}

/*------------------------------------------*/
/*   BORDER SETTINGS FOR DEMOS
/*------------------------------------------*/

.b-top {
  border-top: 1px solid #ddd;
}
.b-bottom {
  border-bottom: 1px solid #ddd;
}
.b-left {
  border-left: 1px solid #ddd;
}
.b-right {
  border-right: 1px solid #ddd;
}

/* ==========================================================================
  02. TYPOGRAPHY
  =========================================================================== */

/*------------------------------------------*/
/*  HEADERS
/*------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #283034;
  font-family: "Muli", sans-serif;
  font-weight: 700;
  letter-spacing: -0.5px;
}

/* Header H5 */
h5.h5-xs {
  font-size: 1.125rem;
} /* 18px */
h5.h5-sm {
  font-size: 1.1875rem;
} /* 19px */
h5.h5-md {
  font-size: 1.25rem;
} /* 20px */
h5.h5-lg {
  font-size: 1.375rem;
} /* 22px */
h5.h5-xl {
  font-size: 1.5rem;
} /* 24px */

/* Header H4 */
h4.h4-xs {
  font-size: 1.625rem;
} /* 26px */
h4.h4-sm {
  font-size: 1.75rem;
} /* 28px */
h4.h4-md {
  font-size: 1.875rem;
} /* 30px */
h4.h4-lg {
  font-size: 2rem;
} /* 32px */
h4.h4-xl {
  font-size: 2.125rem;
} /* 34px */

/* Header H3 */
h3.h3-xs {
  font-size: 2.375rem;
} /* 38px */
h3.h3-sm {
  font-size: 2.5rem;
} /* 40px */
h3.h3-md {
  font-size: 2.625rem;
} /* 42px */
h3.h3-lg {
  font-size: 2.75rem;
} /* 44px */
h3.h3-xl {
  font-size: 3rem;
} /* 48px */

/* Header H2 */
h2.h2-xs {
  font-size: 3.25rem;
} /* 52px */
h2.h2-sm {
  font-size: 3.375rem;
} /* 54px */
h2.h2-md {
  font-size: 3.5rem;
} /* 56px */
h2.h2-lg {
  font-size: 3.75rem;
} /* 60px */
h2.h2-xl {
  font-size: 4rem;
} /* 64px */
h2.h2-huge {
  font-size: 5rem;
} /* 80px */

/*------------------------------------------*/
/*  PARAGRAPHS
/*------------------------------------------*/

p.p-sm {
  font-size: 0.95rem;
} /* 15.2px */
p {
  font-size: 1.05rem;
} /* 16.8px */
p.p-md {
  font-size: 1.125rem;
} /* 18px */
p.p-lg {
  font-size: 1.25rem;
} /* 20px */
p.p-xl {
  font-size: 1.3175rem;
} /* 22px */

/*------------------------------------------*/
/*  LINK SETTINGS
/*------------------------------------------*/

a {
  color: #333;
  text-decoration: none;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

a:hover {
  color: #666;
  text-decoration: none;
}

a:focus {
  outline: none;
  text-decoration: none;
}

/*------------------------------------------*/
/*  LISTS
/*------------------------------------------*/

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/*------------------------------------------*/
/*  TEXT LIST
/*------------------------------------------*/

ul.txt-list {
  list-style: disc;
  margin-left: 15px;
}

ul.txt-list li,
ul.ico-list li {
  font-size: 1.05rem;
  margin-bottom: 7px;
}

ul.txt-list li:last-child,
ul.ico-list li:last-child {
  margin-bottom: 0;
}

ul.txt-list li span {
  font-weight: 700;
}

ul.ico-list li i {
  text-align: center;
  float: left;
  font-size: 0.85rem;
  margin-top: 6px;
  margin-right: 10px;
}

/*------------------------------------------*/
/*  BUTTON SETTINGS
/*------------------------------------------*/

.btn {
  background-color: transparent;
  color: #fff;
  font-size: 15px;
  line-height: 1;
  font-weight: 700;
  padding: 13px 26px;
  border: 2px solid transparent;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

/*------------------------------------------*/
/*  Button Size
/*------------------------------------------*/

.btn.btn-sm {
  font-size: 14px;
  padding: 12px 24px;
}

.btn.btn-md {
  font-size: 16px;
  padding: 14px 28px;
}

.btn.btn-lg {
  font-size: 18px;
  padding: 18px 45px;
}

/*------------------------------------------*/
/*  Button Color
/*------------------------------------------*/

.btn-tra {
  color: #333;
  background-color: transparent;
  border-color: transparent !important;
}

.btn-tra-black,
.white-color .btn-tra-black {
  color: #333;
  background-color: transparent;
  border-color: #333;
}

.btn-black,
.white-color .btn-black {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.btn-tra-grey,
.white-color .btn-tra-grey {
  color: #333;
  background-color: transparent;
  border-color: #e3e3e3;
}

.btn-tra-white {
  color: #fff;
  background-color: transparent;
  border-color: #fff !important;
}

.btn-tra-blue,
.scroll .btn-tra-blue,
.white-color .btn-tra-blue {
  color: #3284bf !important;
  background-color: transparent;
  border-color: #3284bf;
}

.btn-blue,
.scroll .btn-blue,
.white-color .btn-blue {
  color: #fff !important;
  background-color: #3284bf;
  border-color: #3284bf;
}

.btn-carrot,
.scroll .btn-carrot,
.white-color .btn-carrot {
  color: #fff !important;
  background-color: #d05a24;
  border-color: #d05a24;
}

.btn-green,
.scroll .btn-green,
.white-color .btn-green {
  color: #fff !important;
  background-color: #20ab5c;
  border-color: #20ab5c;
}

.btn-lightgreen,
.scroll .btn-lightgreen,
.white-color .btn-lightgreen {
  color: #fff !important;
  background-color: #8dd17e;
  border-color: #8dd17e;
}

.btn-skyblue,
.scroll .btn-skyblue,
.white-color .btn-skyblue {
  color: #fff !important;
  background-color: #1ba1f0;
  border-color: #1ba1f0;
}

.btn-orange,
.scroll .btn-orange,
.white-color .btn-orange {
  color: #fff !important;
  background-color: #ff4500;
  border-color: #ff4500;
}

.btn-red,
.scroll .btn-red,
.white-color .btn-red {
  color: #fff !important;
  background-color: #ff6d33;
  border-color: #ff6d33;
}

.btn-tra-rose,
.scroll .btn-tra-rose,
.white-color .btn-tra-rose {
  color: #ff1f59 !important;
  background-color: transparent;
  border-color: #ff1f59;
}

.btn-rose,
.scroll .btn-rose,
.white-color .btn-rose {
  color: #fff !important;
  background-color: #ff1f59;
  border-color: #ff1f59;
}

.btn-teal,
.scroll .btn-teal,
.white-color .btn-teal {
  color: #fff !important;
  background-color: #109d87;
  border-color: #109d87;
}

.btn-yellow,
.scroll .btn-yellow,
.white-color .btn-yellow {
  color: #fff !important;
  background-color: #ffb605;
  border-color: #ffb605;
}

/*------------------------------------------*/
/*  Button Hover
/*------------------------------------------*/

.white-hover:hover,
.scroll .white-hover:hover {
  color: #333 !important;
  background-color: #fff;
  border-color: #fff;
}

.black-hover:hover,
.scroll .black-hover:hover {
  color: #fff !important;
  background-color: #333;
  border-color: #333;
}

.grey-hover:hover,
.scroll .grey-hover:hover {
  color: #333 !important;
  background-color: #ddd;
  border-color: #ddd;
}

.tra-white-hover:hover,
.scroll .tra-white-hover:hover .white-color .tra-white-hover:hover {
  color: #fff !important;
  background-color: transparent;
  border-color: #fff !important;
}

.tra-black-hover:hover,
.scroll .tra-black-hover:hover .white-color .tra-black-hover:hover {
  color: #333 !important;
  background-color: transparent;
  border-color: #333 !important;
}

.tra-grey-hover:hover,
.scroll .tra-grey-hover:hover {
  color: #333 !important;
  background-color: transparent;
  border-color: #ddd;
}

.blue-hover:hover,
.scroll .blue-hover:hover,
.white-color .blue-hover:hover {
  color: #fff !important;
  background-color: #3284bf;
  border-color: #3284bf;
}

.carrot-hover:hover,
.scroll .carrot-hover:hover,
.white-color .carrot-hover:hover {
  color: #fff !important;
  background-color: #d05a24;
  border-color: #d05a24 !important;
}

.green-hover:hover,
.scroll .green-hover:hover,
.white-color .green-hover:hover {
  color: #fff !important;
  background-color: #20ab5c;
  border-color: #20ab5c !important;
}

.lightgreen-hover:hover,
.scroll .lightgreen-hover:hover,
.white-color .lightgreen-hover:hover {
  color: #fff !important;
  background-color: #8dd17e;
  border-color: #8dd17e !important;
}

.skyblue-hover:hover,
.scroll .skyblue-hover:hover,
.white-color .skyblue-hover:hover {
  color: #fff !important;
  background-color: #1ba1f0;
  border-color: #1ba1f0 !important;
}

.orange-hover:hover,
.scroll .orange-hover:hover,
.white-color .orange-hover:hover {
  color: #fff !important;
  background-color: #ff4500;
  border-color: #ff4500 !important;
}

.red-hover:hover,
.scroll .red-hover:hover,
.white-color .red-hover:hover {
  color: #fff !important;
  background-color: #ff6d33;
  border-color: #ff6d33 !important;
}

.rose-hover:hover,
.scroll .rose-hover:hover,
.white-color .rose-hover:hover {
  color: #fff !important;
  background-color: #ff1f59;
  border-color: #ff1f59 !important;
}

.teal-hover:hover,
.scroll .teal-hover:hover,
.white-color .teal-hover:hover {
  color: #fff !important;
  background-color: #109d87;
  border-color: #109d87 !important;
}

/*------------------------------------------*/
/*  Button Focus
/*------------------------------------------*/

.btn:focus {
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn.btn-black:focus {
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-tra-black:focus,
.btn.btn-tra-grey:focus {
  color: #333;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  VIDEO POPUP LINK
/*------------------------------------------*/

.play-btn {
  position: relative;
}

.video-play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 30px;
  height: 52px;
  border-radius: 50%;
  padding: 14px 25px 14px 29px;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 105px;
  height: 105px;
  background: rgba(248, 248, 248, 0.5);
  border-radius: 50%;
  -webkit-animation: pulse-border 2200ms ease-out infinite;
  animation: pulse-border 2200ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  transition: all 500ms;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 36px solid #fff;
  border-top: 26px solid transparent;
  border-bottom: 26px solid transparent;
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

/*------------------------------------------*/
/*  VIDEO BUTTON COLOR 
/*------------------------------------------*/

.play-btn-blue .video-play-button:after {
  background: #3284bf;
}
.play-btn-blue .video-play-button:hover:after {
  background: #3284bf;
}

.play-btn-carrot .video-play-button:after {
  background: #d05a24;
}
.play-btn-carrot .video-play-button:hover:after {
  background: #d05a24;
}

.play-btn-skyblue .video-play-button:after {
  background: #1ba1f0;
}
.play-btn-skyblue .video-play-button:hover:after {
  background: #1ba1f0;
}

.play-btn-green .video-play-button:after {
  background: #20ab5c;
}
.play-btn-green .video-play-button:hover:after {
  background: #20ab5c;
}

.play-btn-lightgreen .video-play-button:after {
  background: #8dd17e;
}
.play-btn-lightgreen .video-play-button:hover:after {
  background: #8dd17e;
}

.play-btn-orange .video-play-button:after {
  background: #ff4500;
}
.play-btn-orange .video-play-button:hover:after {
  background: #ff4500;
}

.play-btn-red .video-play-button:after {
  background: #ff6d33;
}
.play-btn-red .video-play-button:hover:after {
  background: #ff6d33;
}

.play-btn-rose .video-play-button:after {
  background: #ff1f59;
}
.play-btn-rose .video-play-button:hover:after {
  background: #ff1f59;
}

.play-btn-teal .video-play-button:after {
  background: #109d87;
}
.play-btn-teal .video-play-button:hover:after {
  background: #109d87;
}

.play-btn-yellow .video-play-button:after {
  background: #ffb605;
}
.play-btn-yellow .video-play-button:hover:after {
  background: #ffb605;
}

/*------------------------------------------*/
/*  COUNTDOWN ELEMENT
/*------------------------------------------*/

#clock {
  display: inline-block;
}

#clock .cbox {
  float: left;
  text-align: center;
  padding-right: 10px;
  display: inline-block;
}

.banner-2-txt #clock .cbox {
  padding: 25px 15px;
  margin-right: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

span.countdown-txt {
  position: relative;
  top: -8px;
  font-size: 1.25rem;
  font-family: "Muli", sans-serif;
  line-height: 1;
  font-weight: 600;
  margin: 0;
  padding-right: 8px;
}

.banner-2-txt span.countdown-txt {
  display: block;
  font-size: 1.35rem;
  font-weight: 400;
  top: 0;
  padding-right: 0;
  margin-bottom: 15px;
}

/*------------------------------------------*/
/*  Countdown Digit
/*------------------------------------------*/

#clock span.cbox-digit {
  font-size: 1.5rem;
  font-family: "Muli", sans-serif;
  line-height: 1;
  font-weight: 800;
  letter-spacing: -1px;
}

#clock span.cbox-txt {
  font-size: 1.5rem;
  font-family: "Muli", sans-serif;
  line-height: 1;
  font-weight: 800;
  margin-bottom: 0;
}

.banner-2-txt #clock span.cbox-digit,
.banner-2-txt #clock span.cbox-txt {
  font-size: 2.35rem;
  font-weight: 700;
}

/*------------------------------------------*/
/*  BOX ICONS
/*------------------------------------------*/

.icon-xs [class*="flaticon-"]:before,
.icon-xs [class*="flaticon-"]:after {
  font-family: Flaticon;
  font-size: 3.25rem;
  line-height: 1 !important;
}

.icon-sm [class*="flaticon-"]:before,
.icon-sm [class*="flaticon-"]:after {
  font-family: Flaticon;
  font-size: 3.75rem;
  line-height: 1 !important;
}

.icon-md [class*="flaticon-"]:before,
.icon-md [class*="flaticon-"]:after {
  font-family: Flaticon;
  font-size: 4.125rem;
  line-height: 1 !important;
}

.icon-lg [class*="flaticon-"]:before,
.icon-lg [class*="flaticon-"]:after {
  font-family: Flaticon;
  font-size: 4.5rem;
  line-height: 1 !important;
}

.icon-xl [class*="flaticon-"]:before,
.icon-xl [class*="flaticon-"]:after {
  font-family: Flaticon;
  font-size: 5rem;
  line-height: 1 !important;
}

/*------------------------------------------*/
/*   PNG ICONS SETTINGS
/*------------------------------------------*/

.img-150 {
  width: 150px;
  height: 150px;
}
.img-145 {
  width: 145px;
  height: 145px;
}
.img-140 {
  width: 140px;
  height: 140px;
}
.img-135 {
  width: 135px;
  height: 135px;
}
.img-130 {
  width: 130px;
  height: 130px;
}
.img-125 {
  width: 125px;
  height: 125px;
}
.img-120 {
  width: 120px;
  height: 120px;
}
.img-115 {
  width: 115px;
  height: 115px;
}
.img-110 {
  width: 110px;
  height: 110px;
}
.img-105 {
  width: 105px;
  height: 105px;
}
.img-100 {
  width: 100px;
  height: 100px;
}
.img-95 {
  width: 95px;
  height: 95px;
}
.img-90 {
  width: 90px;
  height: 90px;
}
.img-85 {
  width: 85px;
  height: 85px;
}
.img-80 {
  width: 80px;
  height: 80px;
}
.img-75 {
  width: 75px;
  height: 75px;
}
.img-70 {
  width: 70px;
  height: 70px;
}
.img-65 {
  width: 65px;
  height: 65px;
}
.img-60 {
  width: 60px;
  height: 60px;
}
.img-55 {
  width: 55px;
  height: 55px;
}
.img-50 {
  width: 50px;
  height: 50px;
}
.img-45 {
  width: 45px;
  height: 45px;
}
.img-40 {
  width: 40px;
  height: 40px;
}
.img-35 {
  width: 35px;
  height: 35px;
}
.img-30 {
  width: 30px;
  height: 30px;
}

/*------------------------------------------*/
/*  SECTION TITLE
/*------------------------------------------*/

.section-title {
  position: relative;
  padding-right: 48%;
}

#hboxes-1 .section-title {
  margin-bottom: 45px;
}

.section-title.title-centered {
  text-align: center;
  padding: 0 20%;
}

.section-title h3 {
  margin-bottom: 12px;
}

.section-title h4 {
  margin-bottom: 8px;
}

#hboxes-1 .section-title h4 {
  line-height: 1;
  margin-bottom: 8px;
}

.section-title p {
  color: #888;
  margin-bottom: 0;
}

.section-title.title-centered p {
  padding: 0 5%;
}

#hboxes-1 .section-title p {
  color: #757575;
  margin-top: 0;
}

.title-btn {
  position: absolute;
  right: 15px;
  bottom: 5px;
}

/*------------------------------------------*/
/*  TEXT COLOR
/*------------------------------------------*/

.white-color,
.white-color h2,
.white-color h3,
.white-color h4,
.white-color h5,
.white-color h6,
.white-color p,
.white-color a,
.white-color li,
.white-color i,
.white-color span {
  color: #fff;
}

.grey-color,
.grey-color h2,
.grey-color h3,
.grey-color h4,
.grey-color h5,
.grey-color h6,
.grey-color p,
.grey-color a,
.grey-color li,
.grey-color i,
.grey-color span,
.white-color .grey-color {
  color: #757575;
}

.lightgrey-color,
.lightgrey-color h2,
.lightgrey-color h3,
.lightgrey-color h4,
.lightgrey-color h5,
.lightgrey-color h6,
.lightgrey-color p,
.lightgrey-color a,
.lightgrey-color li,
.lightgrey-color i,
.lightgrey-color span,
.white-color .lightgrey-color {
  color: #bbb;
}

.blue-color,
.blue-color h2,
.blue-color h3,
.blue-color h4,
.blue-color h5,
.blue-color h6,
.blue-color p,
.blue-color a,
.blue-color li,
.blue-color i,
.blue-color span,
.grey-color .blue-color,
.white-color .blue-color {
  color: #3284bf;
}

.skyblue-color,
.skyblue-color h2,
.skyblue-color h3,
.skyblue-color h4,
.skyblue-color h5,
.skyblue-color h6,
.skyblue-color p,
.skyblue-color a,
.skyblue-color li,
.skyblue-color i,
.skyblue-color span,
.grey-color .skyblue-color,
.white-color .skyblue-color {
  color: #1ba1f0;
}

.carrot-color,
.carrot-color h2,
.carrot-color h3,
.carrot-color h4,
.carrot-color h5,
.carrot-color h6,
.carrot-color p,
.carrot-color a,
.carrot-color li,
.carrot-color i,
.carrot-color span,
.grey-color .carrot-color,
.white-color .carrot-color {
  color: #d05a24;
}

.green-color,
.green-color h2,
.green-color h3,
.green-color h4,
.green-color h5,
.green-color h6,
.green-color p,
.green-color a,
.green-color li,
.green-color i,
.green-color span,
.grey-color .green-color,
.white-color .green-color {
  color: #20ab5c;
}

.lightgreen-color,
.lightgreen-color h2,
.lightgreen-color h3,
.lightgreen-color h4,
.lightgreen-color h5,
.lightgreen-color h6,
.lightgreen-color p,
.lightgreen-color a,
.lightgreen-color li,
.lightgreen-color i,
.lightgreen-color span,
.grey-color .lightgreen-color,
.white-color .lightgreen-color {
  color: #8dd17e;
}

.orange-color,
.orange-color h2,
.orange-color h3,
.orange-color h4,
.orange-color h5,
.orange-color h6,
.orange-color p,
.orange-color a,
.orange-color li,
.orange-color i,
.orange-color span,
.grey-color .orange-color,
.white-color .orange-color {
  color: #ff4500;
}

.red-color,
.red-color h2,
.red-color h3,
.red-color h4,
.red-color h5,
.red-color h6,
.red-color p,
.red-color a,
.red-color li,
.red-color i,
.red-color span,
.grey-color .red-color,
.white-color .red-color {
  color: #ff6d33;
}

.rose-color,
.rose-color h2,
.rose-color h3,
.rose-color h4,
.rose-color h5,
.rose-color h6,
.rose-color p,
.rose-color a,
.rose-color li,
.rose-color i,
.rose-olor span,
.grey-color .rose-color,
.white-color .rose-color {
  color: #ff1f59;
}

.teal-color,
.teal-color h2,
.teal-color h3,
.teal-color h4,
.teal-color h5,
.teal-color h6,
.teal-color p,
.teal-color a,
.teal-color li,
.teal-color i,
.teal-color span,
.grey-color .teal-color,
.white-color .teal-color {
  color: #109d87;
}

/*------------------------------------------*/
/*  TEXT BLOCK TYPOGRAPHY
/*------------------------------------------*/

.txt-block h3.h3-sm {
  margin-bottom: 25px;
}

.txt-block h5.h5-lg {
  margin-top: 5px;
  margin-bottom: 20px;
}

.txt-block .btn {
  margin-top: 20px;
}

/*------------------------------------------*/
/*  IMAGE BLOCK SETTINGS
/*------------------------------------------*/

.img-block {
  text-align: center;
}

/* ==========================================================================
  03. PRELOAD SPINNER
  =========================================================================== */

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
}

#loading {
  background-color: #517082;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999999;
  margin-top: 0px;
  top: 0px;
}

#loading-center {
  width: 100%;
  height: 100%;
  position: relative;
}

.cssload-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -20px;
  margin-left: -40px;
}

.cssload-container {
  display: block;
  margin: 47px auto;
  width: 95px;
}

.cssload-loading i {
  width: 19px;
  height: 19px;
  display: inline-block;
  border-radius: 50%;
  background: rgb(0, 179, 213);
}
.cssload-loading i:first-child {
  opacity: 0;
  animation: cssload-loading-ani2 0.475s linear infinite;
  -o-animation: cssload-loading-ani2 0.475s linear infinite;
  -ms-animation: cssload-loading-ani2 0.475s linear infinite;
  -webkit-animation: cssload-loading-ani2 0.475s linear infinite;
  -moz-animation: cssload-loading-ani2 0.475s linear infinite;
  transform: translate(-19px);
  -o-transform: translate(-19px);
  -ms-transform: translate(-19px);
  -webkit-transform: translate(-19px);
  -moz-transform: translate(-19px);
}
.cssload-loading i:nth-child(2),
.cssload-loading i:nth-child(3) {
  animation: cssload-loading-ani3 0.475s linear infinite;
  -o-animation: cssload-loading-ani3 0.475s linear infinite;
  -ms-animation: cssload-loading-ani3 0.475s linear infinite;
  -webkit-animation: cssload-loading-ani3 0.475s linear infinite;
  -moz-animation: cssload-loading-ani3 0.475s linear infinite;
}
.cssload-loading i:last-child {
  animation: cssload-loading-ani1 0.475s linear infinite;
  -o-animation: cssload-loading-ani1 0.475s linear infinite;
  -ms-animation: cssload-loading-ani1 0.475s linear infinite;
  -webkit-animation: cssload-loading-ani1 0.475s linear infinite;
  -moz-animation: cssload-loading-ani1 0.475s linear infinite;
}

@keyframes cssload-loading-ani1 {
  100% {
    transform: translate(38px);
    opacity: 0;
  }
}

@-o-keyframes cssload-loading-ani1 {
  100% {
    -o-transform: translate(38px);
    opacity: 0;
  }
}

@-ms-keyframes cssload-loading-ani1 {
  100% {
    -ms-transform: translate(38px);
    opacity: 0;
  }
}

@-webkit-keyframes cssload-loading-ani1 {
  100% {
    -webkit-transform: translate(38px);
    opacity: 0;
  }
}

@-moz-keyframes cssload-loading-ani1 {
  100% {
    -moz-transform: translate(38px);
    opacity: 0;
  }
}

@keyframes cssload-loading-ani2 {
  100% {
    transform: translate(19px);
    opacity: 1;
  }
}

@-o-keyframes cssload-loading-ani2 {
  100% {
    -o-transform: translate(19px);
    opacity: 1;
  }
}

@-ms-keyframes cssload-loading-ani2 {
  100% {
    -ms-transform: translate(19px);
    opacity: 1;
  }
}

@-webkit-keyframes cssload-loading-ani2 {
  100% {
    -webkit-transform: translate(19px);
    opacity: 1;
  }
}

@-moz-keyframes cssload-loading-ani2 {
  100% {
    -moz-transform: translate(19px);
    opacity: 1;
  }
}

@keyframes cssload-loading-ani3 {
  100% {
    transform: translate(19px);
  }
}

@-o-keyframes cssload-loading-ani3 {
  100% {
    -o-transform: translate(19px);
  }
}

@-ms-keyframes cssload-loading-ani3 {
  100% {
    -ms-transform: translate(19px);
  }
}

@-webkit-keyframes cssload-loading-ani3 {
  100% {
    -webkit-transform: translate(19px);
  }
}

@-moz-keyframes cssload-loading-ani3 {
  100% {
    -moz-transform: translate(19px);
  }
}

/* ==========================================================================
  04. HEADER & NAVIGATION
  =========================================================================== */

#header {
  width: 100%;
  display: block;
  padding-top: 90px;
}

.header-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.headerwp {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0;
}

.posrlt {
  position: relative;
}

/*------------------------------------------*/
/*   NAVIGATION MENU
/*------------------------------------------*/

.wsmainfull {
  width: 100%;
  height: auto;
  z-index: 1031;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.tra-menu .wsmainfull {
  background-color: transparent !important;
  padding: 20px 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.white-menu .wsmainfull {
  background-color: #fff !important;
  padding: 10px 0;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

.dark-menu .wsmainfull {
  background-color: #283034 !important;
  padding: 10px 0;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

/*------------------------------------------*/
/*  HEADER LINK
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > a {
  display: block;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  margin: 0 7px;
  padding: 10px 15px;
  line-height: 50px;
  text-decoration: none;
}

.navbar-dark .wsmenu > .wsmenu-list > li > a {
  color: #444;
}

.navbar-light .wsmenu > .wsmenu-list > li > a {
  color: #fff;
}

.wsmenu > .wsmenu-list > li > a.last-link {
  padding: 10px 0px;
  margin: 0 0 0 7px;
}

.wsmenu > .wsmenu-list > li > a .wsarrow:after {
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-top: 4px solid;
  content: "";
  float: right;
  right: 0;
  height: 0;
  margin: 0 0 0 14px;
  position: absolute;
  text-align: right;
  top: 33px;
  width: 0;
}

li > .lang-select {
  margin-right: 15px;
}

.wsmenu > .wsmenu-list > li > a img,
.wsmenu > .wsmenu-list > li ul.sub-menu a img {
  width: 26px;
  height: 26px;
  margin-right: 3px;
}

/*------------------------------------------*/
/*  HEADER BUTTON
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li a.btn {
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.25px;
  margin-top: 13px;
  margin-left: 15px;
  padding: 6px 30px;
}

/*------------------------------------------*/
/*  HEADER SUBMENU
/*------------------------------------------*/

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a {
  font-size: 16px;
  padding: 11px 9px;
}

.wsmenu > .wsmenu-list > li > ul.sub-menu > li > a:hover {
  padding: 11px 9px 11px 15px;
}

.wsmenu > .wsmenu-list > li > .wsmegamenu .title {
  font-size: 16px;
  line-height: 1.3;
  font-weight: 800;
  font-family: "Muli", sans-serif;
  letter-spacing: 0;
}

/*------------------------------------------*/
/*   MEGAMENU LATEST NEWS
/*------------------------------------------*/

.wsmegamenu .latest-news li {
  padding: 15px 0;
  border-bottom: 1px dashed #c0c0c0;
}

.wsmegamenu .latest-news li:first-child {
  padding: 0 0 15px;
}

.wsmegamenu .latest-news li:last-child {
  padding: 15px 0 0;
  border-bottom: 0;
}

.wsmegamenu .latest-news img {
  text-align: center;
  float: left;
  width: 85px;
  height: 85px;
}

.wsmegamenu .post-summary {
  overflow: hidden;
  padding-left: 20px;
}

/*------------------------------------------*/
/*  MEGAMENU LATEST NEWS TYPOGRAPHY
--------------------------------------------*/

.wsmegamenu .post-summary a {
  color: #888 !important;
  font-size: 16px;
  font-family: "Muli", sans-serif;
  font-weight: 400;
}

.wsmegamenu h5.h5-md a {
  color: #333 !important;
  margin-bottom: 0;
}

.wsmegamenu .latest-news .post-summary a:hover {
  color: #333 !important;
  text-decoration: underline;
}

.wsmegamenu .latest-news p {
  color: #999;
  font-size: 15px;
  font-weight: 300;
  margin-top: 6px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  NAVBAR SCROLL
/*------------------------------------------*/

.tra-menu .wsmainfull.scroll,
.white-menu .wsmainfull.scroll,
.dark-menu.dark-scroll .wsmainfull.scroll {
  background-color: #fff !important;
  padding: 3px 0;
  -webkit-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  -moz-box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
  box-shadow: 0 2px 3px rgba(96, 96, 96, 0.1);
}

/*------------------------------------------*/
/*  Navigation Menu
/*------------------------------------------*/

.tra-menu.navbar-light .scroll .wsmenu > .wsmenu-list > li a {
  color: #444;
}

.tra-menu.navbar-light.dark-scroll .scroll .wsmenu > .wsmenu-list > li a,
.tra-menu.navbar-dark.dark-scroll .scroll .wsmenu > .wsmenu-list > li a {
  color: #fff;
}

.tra-menu.navbar-light.dark-scroll
  .scroll
  .wsmenu
  > .wsmenu-list
  .sub-menu
  li
  a,
.tra-menu.navbar-dark.dark-scroll .scroll .wsmenu > .wsmenu-list .sub-menu li a,
.tra-menu.navbar-light.dark-scroll
  .scroll
  .wsmenu
  > .wsmenu-list
  li
  .wsmegamenu
  a,
.tra-menu.navbar-dark.dark-scroll
  .scroll
  .wsmenu
  > .wsmenu-list
  li
  .wsmegamenu
  a {
  color: #666;
}

/*------------------------------------------*/
/*  Logo Image
/*------------------------------------------*/

.logo-white,
.logo-black {
  display: block;
}

.navbar-light .logo-black,
.navbar-dark .logo-white,
.tra-menu.navbar-light .scroll .logo-white,
.tra-menu.navbar-light.dark-scroll .scroll .logo-black,
.tra-menu.navbar-dark.dark-scroll .scroll .logo-black {
  display: none;
}

.tra-menu.navbar-light .scroll .logo-black,
.tra-menu.navbar-light.dark-scroll .scroll .logo-white,
.tra-menu.navbar-dark.dark-scroll .scroll .logo-white {
  display: block;
}

/* ==========================================================================
  05. HERO
  =========================================================================== */

#hero-2 {
  background-image: url(../../public/images/hero-2.jpg);
  padding-top: 180px;
  padding-bottom: 50px;
}

#hero-3 {
  background-image: url(../../public/images/hero-3.jpg);
  padding-top: 190px;
  padding-bottom: 240px;
}

#hero-4 {
  background-image: url(../../public/images/hero-4.jpg);
  padding-top: 180px;
  padding-bottom: 100px;
}

#hero-6 {
  background-image: url(../../public/images/hero-6.jpg);
  padding-top: 190px;
  padding-bottom: 250px;
}

#hero-8 {
  background-image: url(../../public/images/hero-8.jpg);
  padding-top: 200px;
  padding-bottom: 175px;
}

#hero-9 {
  background-image: url(../../public/images/home-banner.jpg);
  padding-top: 180px;
  padding-bottom: 50px;
}

/*------------------------------------------*/
/*  HERO SLIDER
/*------------------------------------------*/

.slider {
  position: relative;
  max-width: 100%;
  height: 600px;
  margin-top: 85px;
}

.slider .swiper-wrapper {
  margin: 0;
  height: 600px;
}

#hero-7 .slider,
#hero-7 .slider .swiper-wrapper {
  height: 640px;
}

.slider .swiper-wrapper .li {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: inherit;
  overflow: hidden;
}

.slider .swiper-wrapper .li img {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.slider .swiper-wrapper .li .caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.slider .swiper-wrapper .li.active {
  z-index: 2;
}

/*------------------------------------------*/
/*  Slider Animation
/*------------------------------------------*/

.center-align {
  text-align: center;
}

/*------------------------------------------*/
/*  Slider Indicators
/*------------------------------------------*/

.slider .indicators {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: 0;
  z-index: 98;
}

.slider .indicators .swiper-pagination-bullet {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  height: 10px;
  width: 10px;
  border: 2px solid #fff;
  margin: 0 7px;
  opacity: 0.5;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  border-radius: 50%;
}

.slider .indicators .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #fff;
  opacity: 0.65;
}

/*------------------------------------------*/
/*  HERO TEXT
/*------------------------------------------*/

#hero-4 .hero-txt {
  padding-right: 45px;
}

#hero-7 .caption-txt {
  margin-top: -120px;
}

#hero-9 .hero-txt {
  padding-right: 10%;
}

/*------------------------------------------*/
/*  Hero Headers
/*------------------------------------------*/

#hero-1 h2 {
  font-size: 3.35rem;
  font-weight: 900;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: 20px;
}

#hero-2 h3 {
  font-size: 3.15rem;
  font-weight: 800;
  margin-bottom: 20px;
}

#hero-2 h5 {
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 25px;
}

#hero-3 h2 {
  line-height: 1.1;
  font-weight: 400;
  padding: 0 5%;
  letter-spacing: 0;
  margin-bottom: 20px;
}

#hero-3 h2 span {
  font-weight: 700;
}

#hero-4 h2 {
  font-weight: 400;
  margin-bottom: 20px;
}

#hero-4 h2 span {
  font-weight: 800;
}

#hero-5 h2 {
  font-size: 4rem;
  font-weight: 900;
  text-transform: uppercase;
  padding: 0 12%;
  margin-bottom: 20px;
}

#hero-6 h2 {
  letter-spacing: 0;
  margin-bottom: 20px;
}

#hero-7 h2 {
  font-size: 3.65rem;
  letter-spacing: 0;
  padding: 0 11%;
  margin-bottom: 20px;
}

#hero-8 h2 {
  letter-spacing: 0;
  padding: 0 8%;
  margin-bottom: 35px;
}

#hero-8 h4 {
  font-weight: 400;
  letter-spacing: 0;
  margin-bottom: 22px;
}

#hero-9 h3 {
  font-size: 3.45rem;
  font-weight: 400;
  margin-bottom: 20px;
}

#hero-9 h3 span {
  font-weight: 800;
}

/*------------------------------------------*/
/*  Hero Paragraphs
/*------------------------------------------*/

#hero-1 .caption-txt p {
  padding-right: 15%;
  margin-bottom: 30px;
}

#hero-2 .hero-txt p {
  padding-right: 5%;
  margin-bottom: 30px;
}

#hero-3 .hero-txt p {
  padding: 0 10%;
  margin-bottom: 35px;
}

#hero-4 .hero-txt p {
  margin-bottom: 30px;
}

#hero-5 .caption-txt p,
#hero-7 .caption-txt p {
  font-size: 1.35rem;
  padding: 0 20%;
  margin-bottom: 30px;
}

#hero-6 .hero-txt p {
  padding-right: 10%;
}

#hero-9 .hero-txt p {
  padding-right: 5%;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  HERO SEARCH FORM
/*------------------------------------------*/

#hero-1 .hero-form {
  margin: 40px 15% 0 0;
}

#hero-3 .hero-form {
  margin: 0 8%;
}

#hero-6 .hero-form {
  margin: 35px 10% 0 0;
}

/*------------------------------------------*/
/*  HERO SEARCH FORM INPUT
/*------------------------------------------*/

.hero-form .form-control {
  height: 60px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-right: none;
  color: #333;
  font-size: 1.125rem;
  padding: 0 20px;
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  border-radius: 8px 0 0 8px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

/*------------------------------------------*/
/*  Newsletter Form Input Focus
/*------------------------------------------*/

.hero-form .form-control:focus {
  border: 1px solid #ccc;
  border-right: none;
  outline: 0;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Newsletter Form Button
/*------------------------------------------*/

.hero-form .btn {
  height: 60px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-left: none;
  font-size: 1.45rem;
  line-height: 1 !important;
  padding: 0 20px;
  -webkit-border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  border-radius: 0 8px 8px 0;
}

.hero-form .btn i {
  color: #ec5252;
  font-size: 1.125rem;
  line-height: 1;
}

/*------------------------------------------*/
/*  HERO SECTION BOXES
/*------------------------------------------*/

#hboxes-1 .hero-boxes-holder,
#hboxes-2 .hero-boxes-holder {
  position: relative;
  background-color: #fff;
  padding: 45px 40px 15px;
  border: 1px solid #f5f5f5;
  margin-top: -130px;
  z-index: 999;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#hboxes-2 .hero-boxes-holder {
  margin: -80px 25px 0;
}

#hboxes-1 .c2-box-txt h5 {
  line-height: 1;
  margin-top: 15px;
  margin-bottom: 6px;
}

#hboxes-1 .c2-box-txt p {
  line-height: 1;
  margin-bottom: 35px;
}

#hboxes-1 .owl-nav {
  display: none;
}

/*------------------------------------------*/
/*  HERO IMAGE
/*------------------------------------------*/

.hero-2-img {
  padding-left: 30px;
  padding-right: 20px;
}

/* ==========================================================================
  06. ABOUT
  =========================================================================== */

#about-1 {
  padding-top: 25px;
  padding-bottom: 22px;
}

.a4-boxes {
  padding: 0 4%;
}

/*------------------------------------------*/
/*  ABOUT BOX
/*------------------------------------------*/

.abox-1 {
  padding-left: 25px;
}

.abox-4 {
  padding-left: 10px;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  ABOUT BOX TEXT
/*------------------------------------------*/

.abox-1-txt,
.abox-4-txt {
  overflow: hidden;
  padding-left: 20px;
}

/*------------------------------------------*/
/*  ABOUT BOX TYPOGRAPHY
/*------------------------------------------*/

.abox-1 h5 {
  line-height: 1;
  margin-bottom: 1px;
}

.abox-4 h5 {
  line-height: 1;
  margin-bottom: 10px;
}

.a4-txt h5 {
  margin-bottom: 20px;
}

.abox-1 p,
.a4-txt p,
.abox-4 p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  ABOUT BOX ICON
/*------------------------------------------*/

.abox-1 img,
.abox-4 img,
.abox-1 span,
.abox-4 span {
  float: left;
  text-align: center;
}

/*------------------------------------------*/
/*  ABOUT IMAGE
/*------------------------------------------*/

#about-4 .img-block {
  margin-top: 50px;
  margin-bottom: 70px;
}

#about-4 .img-block img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

/* ==========================================================================
  07. SERVICES
  =========================================================================== */

/*------------------------------------------*/
/*  SERVICE BOX
/*------------------------------------------*/

.sbox-1 {
  background-color: #fff;
  border: 1px solid #f5f5f5;
  padding: 30px 25px;
  margin-bottom: 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sbox-2 {
  text-align: center;
  background-color: #fff;
  border: 1px solid #f5f5f5;
  padding: 50px 20px;
  margin-bottom: 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sbox-3 {
  padding: 0 10px;
  margin-bottom: 40px;
}

.sbox-4 {
  padding: 0 10px;
  margin-bottom: 40px;
}

.sbox-5 {
  background-color: #fff;
  border: 1px solid #e2e2e2;
  padding: 40px;
  margin-bottom: 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

.sbox-5:hover {
  border: 1px solid #f5f5f5;
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
}

.sbox-6 {
  width: 20%;
  float: left;
  text-align: center;
  padding: 0 20px;
  margin-bottom: 40px;
}

/*------------------------------------------*/
/*  SERVICE BOX TEXT
/*------------------------------------------*/

.sbox-1-txt {
  overflow: hidden;
  padding-left: 20px;
}

/*------------------------------------------*/
/*  SERVICE BOX TYPOGRAPHY
/*------------------------------------------*/

.sbox-1 h5 {
  margin-bottom: 10px;
}

.sbox-2 h5,
.sbox-3 h5,
.sbox-4 h5,
.sbox-5 h5 {
  margin-top: 20px;
  margin-bottom: 12px;
}

.sbox-6 h5 {
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0 2%;
  margin-top: 15px;
  margin-bottom: 0;
}

.sbox-1 p {
  font-size: 0.975rem;
  margin-bottom: 0;
}

.sbox-2 p,
.sbox-3 p,
.sbox-5 p {
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  SERVICE BOX ICON
/*------------------------------------------*/

.sbox-1 img,
.sbox-1 span {
  float: left;
  text-align: center;
  margin-top: 4px;
}

/*------------------------------------------*/
/*  SERVICE TEXT
/*------------------------------------------*/

.services-6-txt {
  margin-bottom: 70px;
}

.services-6-txt h3 {
  letter-spacing: 0;
  margin-bottom: 15px;
}

.services-6-txt p {
  padding: 0 15%;
  margin-bottom: 30px;
}

/* ==========================================================================
  08.  CATEGORIES
  =========================================================================== */

#categories-1 {
  padding-top: 60px;
  padding-bottom: 54px;
}

/*------------------------------------------*/
/*  CATEGORIE BOX
/*------------------------------------------*/

.c1-box {
  width: 20%;
  float: left;
}

#hboxes-1 .col-lg-2,
#categories-2 .col-lg-2 {
  padding-left: 8px;
  padding-right: 8px;
}

/*------------------------------------------*/
/*  CATEGORIE BOX ICON
/*------------------------------------------*/

.c2-box img,
.c2-box-txt img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.c2-box:hover > img,
.c2-box-txt:hover > img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

/*------------------------------------------*/
/*  CATEGORIE BOX TEXT
/*------------------------------------------*/

.c1-box-txt {
  text-align: left !important;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  margin: 0 8px 16px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.bg-dark .c1-box-txt {
  background-color: #1d1e29;
  border: 1px solid #444;
}

.c2-box {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  padding: 25px 15px;
  margin-bottom: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.c1-box-txt:hover,
.c2-box:hover {
  border: 1px solid #f5f5f5;
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
}

.bg-dark .c1-box-txt:hover {
  background-color: #1d1e29;
  border: 1px solid #777;
}

.c3-box {
  text-align: center !important;
  padding: 35px 25px 45px;
  /* margin: 0 8px 30px; */
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.c4-box {
  margin-bottom: 50px;
}

.c4-box-list li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0;
  padding: 0;
}

.c4-box-list li a {
  color: #888;
  font-size: 0.95rem;
  font-weight: 500;
  border-bottom: 1px dotted #888;
  margin-right: 3px;
}

/*------------------------------------------*/
/*  CATEGORIE BOX TYPOGRAPHY
/*------------------------------------------*/

.c1-box-txt h5 {
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 6px;
}

.c2-box h5 {
  font-size: 1rem;
  line-height: 1.3;
  margin-top: 15px;
  margin-bottom: 6px;
}

.cbox-3-txt h5 {
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 4px;
}

.c4-box-ico h5 {
  line-height: 1;
  padding-top: 15px;
  margin-bottom: 4px;
}

.c1-box-txt p,
.c2-box p {
  color: #666;
  font-size: 0.925rem;
  line-height: 1;
  margin-bottom: 0;
}

.bg-dark .c1-box-txt p {
  color: #ddd;
}

.c3-box-txt p {
  line-height: 1;
  margin-bottom: 0;
}

.c4-box-ico p {
  line-height: 1;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  CATEGORIE BOX ICON
/*------------------------------------------*/

.c1-box-txt img {
  float: left;
  position: relative;
  width: 40px;
  height: 40px;
  top: -2px;
  margin-left: 5px;
  margin-right: 15px;
}

.c3-box-icon {
  display: inline-block;
  padding: 25px;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.c4-ico {
  float: left;
  position: relative;
  padding: 12px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  margin-right: 15px;
}

.c4-ico img {
  width: 45px;
  height: 45px;
}

.owl-carousel .swiper-slide .c3-box-icon img {
  width: 72px;
  height: 72px;
  display: inline-block;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

/* ==========================================================================
  09.  COURSES
  =========================================================================== */

#courses-5 {
  padding-top: 30px;
  padding-bottom: 15px;
}

#courses-4 .section-title {
  padding-right: 25%;
}

/*------------------------------------------*/
/*  COURSE BOX
/*------------------------------------------*/

.cbox-1,
.cbox-2 {
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #ddd;
  /* margin: 0 6px 30px; */
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.bg-lightdark .cbox-1 {
  border: 1px solid #333;
}

#courses-3 .cbox-1 {
  margin: 0 0 30px;
}

.cbox-2 {
  margin: 0 0 40px;
}

.cbox-1:hover,
.cbox-2:hover {
  border: 1px solid #f5f5f5;
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
}

.bg-lightdark .cbox-1:hover {
  border: 1px solid #333;
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.95);
}

.courses-grid .col-lg-3 {
  padding-left: 5px;
  padding-right: 5px;
}

.cbox-5 {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 25px;
  margin-bottom: 16px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

#courses-5 .col-md-6 {
  padding-left: 8px;
  padding-right: 8px;
}

.cbox-5:hover {
  border: 1px solid #f5f5f5;
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
}

/*------------------------------------------*/
/*  COURSES MASONRY GRID
/*------------------------------------------*/

#courses-4 .masonry-item {
  width: 25%;
  position: relative;
  padding: 0;
  margin-bottom: 0;
}

.cbox-4 {
  background-color: #fff;
  border: 1px solid #ddd;
  margin: 0 6px 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.cbox-4:hover {
  border: 1px solid #f5f5f5;
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
}

/*------------------------------------------*/
/*  COURSES FILTER BUTTONS
/*------------------------------------------*/

#courses-4 .masonry-filter {
  margin-top: 22px;
}

#courses-4 .title-centered .masonry-filter {
  margin-top: 30px;
}

#courses-4 .masonry-filter button {
  background-color: transparent;
  border: 1px solid transparent;
  color: #959595;
  font-size: 0.95rem;
  font-weight: 400;
  margin-right: 12px;
  padding: 5px 13px;
  cursor: pointer;
  outline: 0px none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*  Filter Button Hover
/*------------------------------------------*/

#courses-4 .masonry-filter button:hover {
  background-color: transparent;
  color: #333;
  border: 1px solid transparent;
  outline: 0px none;
}

/*------------------------------------------*/
/*  Filter Button Checked
/*------------------------------------------*/

#courses-4 .masonry-filter button:focus,
#courses-4 .masonry-filter button.is-checked {
  color: #fff;
  cursor: default;
  background-color: #ff1f59;
  border: 1px solid #ff1f59;
  outline: 0px none;
}

#courses-4 .masonry-filter.hover-blue button.is-checked {
  background-color: #3284bf;
  border: 1px solid #3284bf;
}

#courses-4 .masonry-filter.hover-green button.is-checked {
  background-color: #20ab5c;
  border: 1px solid #20ab5c;
}

#courses-4 .masonry-filter.hover-rose button.is-checked {
  background-color: #ff1f59;
  border: 1px solid #ff1f59;
}

#courses-4 .masonry-filter.hover-yellow button.is-checked {
  background-color: #ffb605;
  border: 1px solid #ffb605;
}

/*------------------------------------------*/
/*  COURSE BOX TEXT
/*------------------------------------------*/

.cbox-1-txt,
.cbox-4-txt {
  padding: 25px 20px;
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}

.cbox-2-txt {
  padding: 35px 20px 25px;
  -webkit-border-radius: 0 0 6px 6px;
  -moz-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}

/*------------------------------------------*/
/*  COURSE BOX TYPOGRAPHY
/*------------------------------------------*/

.cbox-1-txt h5,
.cbox-2-txt h5,
.cbox-4-txt h5 {
  margin-bottom: 12px;
}

.cbox-5-txt h5 {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

p.course-tags {
  margin-bottom: 10px;
}

.cbox-1-txt p.course-tags,
.cbox-4-txt p.course-tags {
  margin-bottom: 15px;
}

.course-tags span {
  background-color: #f0f1f2;
  font-size: 0.915rem;
  padding: 3px 7px;
  margin-right: 3px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.cbox-1-txt p.p-sm,
.cbox-2-txt p.p-sm,
.cbox-4-txt p.p-sm {
  margin-bottom: 12px;
}

.cbox-5-txt p.p-sm {
  line-height: 1;
  margin-bottom: 3px;
}

.cbox-5-data p {
  font-size: 1rem;
  font-family: "Muli", sans-serif;
  font-weight: 400;
}

/*------------------------------------------*/
/*  Course Rating
/*------------------------------------------*/

.course-rating {
  position: relative;
  display: inline-block;
}

.course-rating i {
  color: #ffb605;
  font-size: 0.85rem;
  line-height: 1;
}

#course-details .course-rating i {
  font-size: 0.975rem;
}

.course-rating span {
  color: #888;
  position: relative;
  font-size: 0.925rem;
  line-height: 1;
  font-weight: 400;
  top: 1px;
}

#course-details .course-rating span {
  font-size: 1rem;
  margin-left: 5px;
}

#course-details .cs-rating .course-rating span {
  color: #444;
  font-size: 0.95rem;
  margin-left: 5px;
}

.cr-rating {
  padding-left: 3px;
}

/*------------------------------------------*/
/*  Course Price
/*------------------------------------------*/

.course-price {
  display: block;
  font-size: 1.05rem;
  font-family: "Muli", sans-serif;
  font-weight: 800;
  margin-top: 5px;
}

.cbox-5-price .course-price {
  margin-top: 0;
}

.course-data {
  position: relative;
}

.course-data .course-price {
  position: absolute;
  bottom: -9px;
  right: 15px;
  margin-top: 0;
  padding: 5px 12px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.old-price {
  color: #999;
  font-size: 0.95rem;
  font-weight: 400;
  text-decoration: line-through;
  margin-right: 10px;
}

.cbox-5-price .old-price {
  margin-right: 0;
}

/*------------------------------------------*/
/*  COURSE BOX IMAGE
/*------------------------------------------*/

.cbox-1 .hover-overlay,
.cbox-2 .hover-overlay,
.cbox-4 .hover-overlay {
  -webkit-border-radius: 6px 6px 0 0;
  -moz-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}

/*------------------------------------------*/
/*  ALL COURSES BUTTON
/*------------------------------------------*/

.all-courses-btn {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 40px;
}

/*------------------------------------------*/
/*  OWL CAROUSEL NAVIGATION
/*------------------------------------------*/

.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
}

.owl-carousel.owl-theme .owl-nav [class*="owl-"] {
  background: transparent;
  margin: 0 10px;
  padding: 0;
}

.owl-theme .owl-nav img {
  width: 30px;
  height: 16px;
}

.owl-theme .owl-nav button:focus {
  outline: 0px none;
  box-shadow: none;
}

/* ==========================================================================
  10. TEAM
  =========================================================================== */

.team-member {
  text-align: center;
  margin-bottom: 40px;
}

#team-2 .team-member {
  /* margin: 0 6px 30px; */
}

.tm-meta {
  padding-top: 25px;
}

.tm-meta h5 {
  line-height: 1;
  margin-bottom: 3px;
}

.tm-meta span {
  color: #888;
  display: block;
  margin-bottom: 5px;
}

.tm-meta span a {
  color: #888;
}

.tm-meta p {
  color: #888;
  font-weight: 0.9rem;
  line-height: 1;
  margin-top: 5px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Team Memer Rating
/*------------------------------------------*/

.tm-rating {
  position: relative;
  display: block;
}

.tm-rating i {
  color: #ffb605;
  font-size: 0.925rem;
  line-height: 1;
}

.tm-rating.grey-stars i {
  color: #888;
}

.tm-rating span {
  display: inline-block;
  color: #000;
  font-family: "Muli", sans-serif;
  font-weight: 800;
  margin-left: 4px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Team Member Social Links
/*------------------------------------------*/

.team-member-photo {
  position: relative;
}

.tm-social ul {
  width: 100%;
  display: inline-block;
  padding-left: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 20;
  zoom: 1;
  opacity: 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.tm-3-social {
  margin-top: 30px;
  margin-bottom: 25px;
}

.tm-social ul li,
.tm-3-social ul li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  padding: 0;
}

.tm-social a,
.tm-3-social a {
  width: 38px;
  height: 38px;
  color: #fff;
  font-size: 1.05rem;
  line-height: 38px !important;
  margin-right: 1px;
  display: block;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.tm-3-social a {
  width: 46px;
  height: 46px;
  font-size: 1.25rem;
  line-height: 46px !important;
  margin-right: 3px;
}

.tm-3-social a.ico-facebook,
.tm-social a.ico-facebook {
  background-color: #3b5998;
}
.tm-3-social a.ico-twitter,
.tm-social a.ico-twitter {
  background-color: #00a9ed;
}
.tm-3-social a.ico-instagram,
.tm-social a.ico-instagram {
  background-color: #e44772;
}
.tm-3-social a.ico-dribbble,
.tm-social a.ico-dribbble {
  background-color: #d92d84;
}
.tm-3-social a.ico-behance,
.tm-social a.ico-behance {
  background-color: #2473f6;
}
.tm-3-social a.ico-pinterest,
.tm-social a.ico-pinterest {
  background-color: #ac281a;
}
.tm-3-social a.ico-linkedin,
.tm-social a.ico-linkedin {
  background-color: #015886;
}
.tm-3-social a.ico-google-plus,
.tm-social a.ico-google-plus {
  background-color: #cd1111;
}
.tm-3-social a.ico-youtube,
.tm-social a.ico-youtube {
  background-color: #cd1b20;
}
.tm-3-social a.ico-tumblr,
.tm-social a.ico-tumblr {
  background-color: #3a5976;
}
.tm-3-social a.ico-vk,
.tm-social a.ico-vk {
  background-color: #3b5998;
}

.team-member:hover .tm-social ul {
  bottom: 6%;
  opacity: 1;
  -moz-opacity: 1;
}

/*------------------------------------------*/
/*  Team Member Photo
/*------------------------------------------*/

.team-3-photo {
  margin: 0 20px;
}

.team-3-photo img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}

.team-3-photo p {
  line-height: 1;
  font-weight: 400;
  margin-bottom: 10px;
}

.team-3-photo p span {
  color: #000;
  font-weight: 700;
}

.t-3-links {
  padding: 0 10%;
}

.team-3-photo .btn {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}

/*------------------------------------------*/
/*  Team Member Data
/*------------------------------------------*/

.teacher-data {
  color: #757575;
  font-size: 1.125rem;
  line-height: 1;
  font-weight: 400;
  margin: 7px 0;
}

.team-3-txt h3 {
  line-height: 1;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 7px;
}

.team-3-txt span {
  display: inline-block;
  font-family: "Muli", sans-serif;
  font-size: 1.35rem;
  line-height: 1;
  font-weight: 700;
}

.team-3-txt h5.h5-xl {
  line-height: 1;
  margin-bottom: 20px;
}

/* ==========================================================================
  11. VIDEO
  =========================================================================== */

#video-1 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.video-1-holder {
  background-color: #fff;
  margin: 0 5%;
  padding: 50px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#video-2 {
  background-image: url(../../public/images/video-2.jpg);
  padding-top: 140px;
  padding-bottom: 80px;
}

.video-3-txt {
  background-image: url(../../public/images/video-3.jpg);
}

#video-3-content {
  padding-top: 100px;
  padding-bottom: 350px;
  position: relative;
  z-index: 3;
}

/*------------------------------------------*/
/*  VIDEO TEXT
/*------------------------------------------*/

#video-1 .video-txt {
  padding-left: 15px;
}

#video-2 .video-txt {
  padding-top: 80px;
}

.video-txt h4 {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.video-txt h4 span {
  font-weight: 800;
}

.video-3-txt h3 {
  margin-bottom: 10px;
}

#video-1 .video-txt p {
  margin-bottom: 25px;
}

#video-2 .video-txt p {
  padding: 0 15%;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  VIDEO LINK
/*------------------------------------------*/

#video-1 .video-link {
  padding-right: 15px;
}

.video-1-holder .video-link img {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -o-border-radius: 16px;
  border-radius: 16px;
}

#video-3 .video-link img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.video-3-link {
  margin-top: -310px;
}

/* ==========================================================================
  12. STATISTICS
  =========================================================================== */

#statistic-1 {
  padding-top: 60px;
  padding-bottom: 20px;
}

#statistic-2 {
  padding-top: 80px;
  padding-bottom: 40px;
}

#statistic-3 {
  padding-top: 70px;
  padding-bottom: 30px;
}

/*------------------------------------------*/
/*  STATISTIC BLOCK
/*------------------------------------------*/

.statistic-block {
  margin-bottom: 40px;
  padding: 0 25px;
}

#hboxes-2 .statistic-block {
  margin-bottom: 30px;
}

#statistic-3 .statistic-block {
  padding: 0 10px;
}

/*------------------------------------------*/
/*  STATISTIC BLOCK DIGIT
/*------------------------------------------*/

h5.statistic-number {
  font-size: 3.5rem;
  line-height: 1;
  font-weight: 800;
  letter-spacing: -1px;
  margin-bottom: 16px;
}

#hboxes-2 h5.statistic-number,
#statistic-1 h5.statistic-number {
  float: left;
  text-align: center;
  margin-right: 25px;
  margin-bottom: 12px;
}

#hboxes-2 h5.statistic-number {
  font-size: 3rem;
}

#statistic-3 h5.statistic-number {
  font-size: 2rem;
  letter-spacing: 0;
  margin-bottom: 8px;
}

.statistic-block p.p-md {
  font-family: "Muli", sans-serif;
  font-size: 1.2rem;
  line-height: 1;
  margin-bottom: 0;
}

.statistic-block p {
  font-family: "Muli", sans-serif;
  line-height: 1;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  STATISTIC BLOCK TEXT
/*------------------------------------------*/

.statistic-block-txt {
  overflow: hidden;
  padding-left: 25px;
  border-left: 1px solid rgba(10, 10, 10, 0.2);
}

.white-color .statistic-block-txt {
  border-left: 1px solid rgba(245, 245, 245, 0.2);
}

#statistic-3 .statistic-block-txt {
  padding-left: 20px;
}

.statistic-block-txt h5 {
  font-weight: 800;
  margin-bottom: 3px;
}

.statistic-block a {
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
}

span.s-icon {
  float: left;
  text-align: center;
  margin-right: 20px;
  margin-top: 5px;
}

.statistic-block.icon-xs [class*="flaticon-"]:before,
.statistic-block.icon-xs [class*="flaticon-"]:after {
  font-size: 3.05rem;
}

/* ==========================================================================
  13. PRICING
  =========================================================================== */

/*------------------------------------------*/
/*  PRICING TABLE
/*------------------------------------------*/

#pricing-1 .pricing-table {
  background-color: #fff;
  border: 1px solid #ddd;
  text-align: center;
  padding: 50px 40px;
  margin-bottom: 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

#pricing-1 .pricing-table:hover {
  background-color: #fff;
  border-color: transparent;
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
}

#pricing-2 .pricing-table {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 30px 70px 45px;
  margin: 0 20px 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

/*------------------------------------------*/
/*  Pricing Table Price Plan
/*------------------------------------------*/

.pricing-plan h5 {
  font-size: 1.05rem;
  font-weight: 800;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: 30px;
}

#pricing-2 .pricing-plan h5 {
  font-size: 1.45rem;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0;
  text-transform: none;
  margin-top: 5px;
  margin-bottom: 5px;
}

.pricing-table span.price {
  font-size: 75px;
  line-height: 1;
  font-weight: 800;
  letter-spacing: -2px;
}

.pricing-table sup {
  font-size: 40px;
  font-weight: 500;
  top: -7px;
  right: 3px;
}

.pricing-table sup.coins {
  font-weight: 500;
  font-size: 40px;
  top: -22px;
  left: 1px;
}

.pricing-table p.p-sm {
  color: #999;
  font-size: 1.15rem;
  line-height: 1;
  margin-top: 5px;
  margin-bottom: 0;
}

#pricing-2 .pricing-plan p {
  color: #999;
  font-size: 1.05rem;
  font-weight: 400;
}

/*------------------------------------------*/
/*  Pricing Table Body
/*------------------------------------------*/

ul.features {
  color: #757575;
  font-size: 1.05rem;
  line-height: 1;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 30px;
}

#pricing-2 ul.features {
  font-size: 1rem;
  margin-top: 25px;
  margin-bottom: 25px;
}

ul.features li {
  padding: 12px 5%;
}

#pricing-2 ul.features li {
  position: relative;
  padding: 7px 0;
}

#pricing-2 ul.features li span {
  position: absolute;
  color: #00a344;
  font-size: 1.25rem;
  line-height: 1;
  right: 0;
  bottom: 6px;
}

ul.features li.disabled-option {
  color: #ccc;
}

/*------------------------------------------*/
/*  Pricing Table Button
/*------------------------------------------*/

#pricing-1 .pricing-table .btn,
#pricing-2 .pricing-table .btn {
  display: block;
  width: 100%;
}

/*------------------------------------------*/
/*  PRICING TEXT
/*------------------------------------------*/

.pricing-txt .txt-list li {
  margin-bottom: 12px;
}

/*------------------------------------------*/
/*  PRICING NOTICE TEXT
/*------------------------------------------*/

.pricing-notice {
  margin-top: 30px;
  margin-bottom: 40px;
}

.pricing-notice p {
  padding: 0 8%;
  margin-bottom: 0;
}

.pricing-notice p span {
  font-weight: 700;
  margin-right: 5px;
}

/* ==========================================================================
  14. TESTIMONIALS
  =========================================================================== */

#reviews-3 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.review-1 {
  background-color: #fff;
  border: 1px solid #e2e3e4;
  padding: 40px 30px;
  /* margin: 0 15px 20px; */
  overflow: visible;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.bg-lightgrey .review-1,
.bg-whitesmoke .review-1 {
  border: 1px solid #f5f5f5;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.review-3 {
  background-color: #f0f1f2;
  border: 1px solid #eee;
  padding: 25px 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.review-4 {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 25px 40px;
  margin-bottom: 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

/*------------------------------------------*/
/*  MASONRY GRID TESTIMONIALS
/*------------------------------------------*/

.review-2 {
  width: 50%;
  padding: 0 2%;
  margin: 0 0 60px 0;
}

/*------------------------------------------*/
/*  Testimonial Message Avatar 
/*------------------------------------------*/

.review-1 .author-avatar,
.review-2 .author-avatar,
.review-4 .author-avatar {
  display: inline-block;
  float: left;
}

.owl-carousel .swiper-slide .review-1-author img,
.review-2-author img,
.review-4-author img {
  width: 70px;
  height: 70px;
  display: inline-block;
  margin: 0 15px 0 0;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.quote-ico {
  position: relative;
  margin-bottom: -25px;
}

.quote-ico img,
.owl-carousel .swiper-slide .quote-ico img {
  width: 65px;
  height: 65px;
  opacity: 0.15;
}

/*------------------------------------------*/
/*  Testimonial Message Text
/*------------------------------------------*/

.review-1 p {
  color: #757575;
  line-height: 1.4;
  margin-bottom: 25px;
}

.review-2-txt p {
  font-size: 1.125rem;
  line-height: 1.5;
  margin-bottom: 25px;
}

.review-3 p {
  color: #757575;
  font-size: 1.15rem;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 15px;
}

.review-4-txt p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 15px;
}

/*------------------------------------------*/
/*  Testimonial Author
/*------------------------------------------*/

.review-1-author h5,
.review-2-author h5,
.review-4-author h5 {
  font-weight: 700;
  line-height: 1;
  margin-bottom: 2px;
}

.review-2-author h5,
.review-4-author h5 {
  font-size: 1.075rem;
}

.review-3-author h5 {
  line-height: 1;
  margin-bottom: 5px;
}

.review-1-author span,
.review-2-author span {
  color: #858585;
  font-size: 0.95rem;
  font-weight: 300;
}

.review-3-author span {
  display: block;
  color: #858585;
  font-size: 1.1rem;
  font-weight: 300;
}

.review-4-author span {
  color: #858585;
  font-size: 1rem;
  font-weight: 300;
}

/*------------------------------------------*/
/*  Review Rating
/*------------------------------------------*/

.tst-rating {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 4px;
}

.tst-rating i {
  color: #ffb605;
  font-size: 0.85rem;
  line-height: 1;
}

/*------------------------------------------*/
/*   Reviews FlexSlider Navigation
/*------------------------------------------*/

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 40px;
}

.owl-theme .owl-dots .owl-dot span {
  background: transparent;
  border: 2px solid #bbb;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #bbb;
  border: 2px solid transparent;
}

/* ==========================================================================
  15. BRANDS
  =========================================================================== */

#brands-1 {
  padding-top: 60px;
  padding-bottom: 10px;
}

#brands-1 .section-title {
  margin-bottom: 50px;
}

.video-3-brands {
  margin: 60px 5% 70px;
}

.brands-section p,
.video-3-brands p {
  font-weight: 400;
  margin-bottom: 25px;
}

/*------------------------------------------*/
/*  BRANDS LOGO HOLDER
/*------------------------------------------*/

#brands-1 .brand-logo {
  margin-bottom: 30px;
}

#video-3 .brand-logo img,
#brands-1 .brand-logo img {
  padding: 0 15px;
  opacity: 0.9;
}

/* ==========================================================================
  16. BANNER
  =========================================================================== */

#banner-1 {
  padding-top: 30px;
  padding-bottom: 25px;
}

#banner-3 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.banner-3-holder {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#banner-4 {
  background-image: url(../../public/images/banner-4.jpg);
  padding-top: 120px;
  padding-bottom: 120px;
}

/*------------------------------------------*/
/*  BANNER TEXT
/*------------------------------------------*/

.banner-2-txt {
  padding: 0 25px;
  margin-bottom: 40px;
}

.banner-3-txt {
  padding: 0 15px;
}

.banner-5-txt {
  padding: 50px 35px;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-bottom: 40px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.bg-whitesmoke .banner-5-txt {
  border: 1px solid #f5f5f5;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
}

.banner-5-txt:hover {
  border: 1px solid #f5f5f5;
  box-shadow: 0px 6px 15px 0px rgba(0, 0, 0, 0.15);
}

.b5-txt {
  overflow: hidden;
  padding-left: 25px;
}

/*------------------------------------------*/
/*  BANNER TYPOGRAPHY
/*------------------------------------------*/

.banner-1-txt h5 {
  line-height: 1.3;
  font-weight: 600;
  margin-bottom: 3px;
}

.banner-2-txt h3 {
  line-height: 1.3;
  font-weight: 400;
  margin-bottom: 30px;
}

.banner-2-txt h3 span {
  font-weight: 700;
}

.banner-3-txt h4 {
  line-height: 1.3;
  font-weight: 600;
  margin-bottom: 25px;
}

.banner-4-txt h3 {
  line-height: 1.3;
  font-weight: 600;
  margin-bottom: 15px;
}

.banner-5-txt h4 {
  font-size: 1.4rem;
  line-height: 1;
  margin-bottom: 12px;
}

.banner-1-txt h5 span,
.banner-2-txt h3 span,
.banner-3-txt h4 span {
  font-weight: 800;
}

.banner-4-txt p {
  margin-bottom: 30px;
}

.banner-5-txt p {
  margin-bottom: 17px;
}

/*------------------------------------------*/
/*  BANNER IMAGE
/*------------------------------------------*/

.banner-3-img img {
  -webkit-border-radius: 10px 0 0 10px;
  -moz-border-radius: 10px 0 0 10px;
  -o-border-radius: 10px 0 0 10px;
  border-radius: 10px 0 0 10px;
}

.banner-5-txt img {
  position: relative;
  float: left;
  width: 175px;
  height: 146px;
  top: 3px;
}

/*------------------------------------------*/
/*  BANNER REGISTER FORM
/*------------------------------------------*/

#register-form {
  position: relative;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #f5f5f5;
  padding: 1px 30px;
  margin: 0 15px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

#register-form p {
  color: #333;
  font-size: 17px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 10px;
  padding-left: 5px;
}

/*------------------------------------------*/
/*  REGISTER FORM INPUT
/*------------------------------------------*/

.register-form .form-control {
  border: 1px solid #ccc;
  height: 30px;
  color: #333;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 22px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*  Register Form Placeholder
/*------------------------------------------*/

.register-form .form-control::-moz-placeholder {
  color: #999;
}
.register-form .form-control:-ms-input-placeholder {
  color: #999;
}
.register-form .form-control::-webkit-input-placeholder {
  color: #999;
}

/*------------------------------------------*/
/*  Register Form Message
/*------------------------------------------*/

.register-form-msg {
  width: 100% !important;
  display: block;
  text-align: center;
  margin-top: 15px;
}

.register-form .loading {
  color: #00b2e4;
  font-size: 18px;
  font-family: "Muli", sans-serif;
  font-weight: 600;
}

.error {
  color: #e74c3c;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

/* ==========================================================================
  17. BLOG
  =========================================================================== */

.news-grid {
  margin: 0;
  padding: 0;
}

#news-1 .col-sm-6 {
  padding: 0;
}

.article-1 {
  position: relative;
}

.article-2 {
  padding: 0 20px 0 0;
  margin-bottom: 40px;
}

.article-3 {
  padding-bottom: 50px;
  margin: 0 0 50px;
}

.article-3 .col-md-4 {
  padding-left: 0;
  padding-right: 30px;
}

.article-3 .col-md-8 {
  padding-right: 0;
}

/*------------------------------------------*/
/*  Article-2 Typography
/*------------------------------------------*/

.article-2 h5 {
  line-height: 1.3;
  margin-bottom: 10px;
}

.article-2 a {
  color: #151515;
}

.article-2 a:hover {
  color: #444;
  text-decoration: underline;
}

.article-3 h4 {
  margin-bottom: 5px;
}

.article-2 p {
  margin-bottom: 14px;
}

.article-2 p.p-sm {
  color: #757575;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 10px;
}

.article-3 p {
  margin-bottom: 20px;
}

.article-2 span {
  display: block;
  color: #757575;
  font-size: 0.975rem;
  font-family: "Muli", sans-serif;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 0;
}

.article-3 span {
  display: block;
  font-size: 1.05rem;
  font-weight: 400;
  margin-bottom: 10px;
}

/*------------------------------------------*/
/*  Article Meta
/*------------------------------------------*/

#news-1 .article-meta {
  width: 90%;
  position: absolute;
  left: 25px;
  bottom: 25px;
}

#news-1 .article-meta.text-center {
  width: 96%;
  left: 2%;
  right: 2%;
}

#news-1 .article-meta h5 {
  color: #fff;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 0;
}

#news-1 .article-meta h5 span {
  line-height: 2.35rem !important;
  padding: 5px 0;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 10px 0 0px 0px rgba(0, 0, 0, 0.7),
    -10px 0 0px 0px rgba(0, 0, 0, 0.7);
}

/*------------------------------------------*/
/*  Article Tags Cloud
/*------------------------------------------*/

span.badge {
  display: inline-block;
  font-weight: 600;
  border: none;
  background-color: #fff;
  border: 1px solid #999;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 4px 14px;
  margin-right: 5px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

span.badge a {
  color: #999 !important;
  font-size: 0.975rem;
  font-weight: 400;
  line-height: 1.42857;
}

span.badge:hover {
  background-color: #283034;
  border-color: #283034;
}

span.badge:hover a {
  color: #fff !important;
}

/*------------------------------------------*/
/*  Article Preview Image
/*------------------------------------------*/

.article-3 img,
.blog-post-img img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

/*------------------------------------------*/
/*  Article Preview Hover Overlay
/*------------------------------------------*/

.hover-overlay {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.hover-overlay > img {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -moz-transform: scale(1);
  overflow: hidden;
  -webkit-transition: transform 500ms;
  -moz-transition: transform 500ms;
  -o-transition: transform 500ms;
  transition: transform 500ms;
}

.article-1 .item-overlay {
  background: #283034;
  opacity: 0;
  -moz-opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

/*------------------------------------------*/
/*  Image Hover Effect 
/*------------------------------------------*/

.article-1:hover .hover-overlay > img {
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -moz-transform: scale(1.2);
}

.article-1:hover .item-overlay {
  opacity: 0.6;
  -moz-opacity: 0.6;
}

/*------------------------------------------*/
/*  SINGLE POST
/*------------------------------------------*/

.single-post-wrapper {
  padding: 0 15px;
}

.single-post-data p {
  color: #888;
  font-size: 1.15rem;
  font-weight: 400;
  margin-top: 15px;
}

.single-post-data p a {
  color: #0c849d;
  font-weight: 300;
  text-decoration: underline;
}

.single-post-txt h5 {
  margin-top: 25px;
  margin-bottom: 20px;
}

/*------------------------------------------*/
/*  SINGLE POST QUOTE
/*------------------------------------------*/

.quote {
  margin-top: 40px;
  margin-bottom: 40px;
}

.quote p {
  color: #666;
  font-size: 1.5rem !important;
  font-weight: 300;
  border-left: 3px solid #2dcb21;
  padding-left: 20px;
  margin-top: 25px;
  margin-bottom: 20px;
}

/*------------------------------------------*/
/*  BLOG POST INNER IMAGE
/*------------------------------------------*/

.post-inner-img {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.post-inner-img p {
  margin-top: 30px;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  POST TAGS 
/*------------------------------------------*/

.post-share-links {
  margin-top: 70px;
}

.post-tags-list span a {
  color: #999;
  font-size: 0.95rem;
  font-weight: 400;
  line-height: 1.42857;
  background-color: #fff;
  border: 1px solid #999;
  padding: 7px 16px;
  margin-right: 5px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

.post-tags-list span a:hover {
  color: #fff;
  background-color: #283034;
  border-color: #283034;
}

/*------------------------------------------*/
/*  POST SHARE ICONS
/*-----------------------------------------*/

.post-share-links .share-social-icons {
  display: inline-block;
  padding-left: 0;
}

.post-share-links .share-social-icons li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  padding: 0;
}

.post-share-links .share-social-icons a.share-ico i {
  color: #555;
  width: 33px;
  height: 33px;
  font-size: 21px;
  display: block;
  margin-right: 3px;
}

/*------------------------------------------*/
/*  ABOUT POST AUTHOR
/*------------------------------------------*/

.author-senoff {
  margin: 90px 0 70px;
  padding: 70px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.author-senoff img {
  width: 120px;
  height: 120px;
  float: left;
  text-align: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.author-senoff-txt {
  position: relative;
  overflow: hidden;
  padding-left: 30px;
}

.author-senoff-txt h5.h5-xs {
  color: #757575;
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.author-senoff-txt h5.h5-lg {
  line-height: 1;
  font-weight: 800;
  margin-bottom: 15px;
}

.author-senoff-txt p {
  color: #757575;
  padding-right: 5%;
  margin-bottom: 0;
}

.author-follow-btn {
  display: inline-block;
  position: absolute;
  color: #757575;
  font-size: 0.925rem;
  line-height: 1;
  font-weight: 400;
  padding: 4px 10px;
  top: 1px;
  right: 12px;
  border: 1px solid #888;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

/*------------------------------------------*/
/*  OTHER POSTS TYPOGRAPHY
/*------------------------------------------*/

.other-posts h5 {
  color: #777;
  font-weight: 800;
  letter-spacing: -0.5px;
  margin-bottom: 8px;
}

.other-posts h5 i {
  font-size: 0.925rem;
  padding: 0 3px;
  position: relative;
  top: -1px;
}

.prev-post a,
.next-post a {
  color: #999;
  font-size: 1.1rem;
  line-height: 1.2;
}

.all-posts a {
  color: #999;
  font-size: 1.75rem;
  line-height: 1;
}

.prev-post a:hover,
.next-post a:hover {
  color: #000;
  text-decoration: underline;
}

.all-posts a:hover {
  color: #333;
}

/*------------------------------------------*/
/*  POST COMMENTS
/*------------------------------------------*/

.post-comments {
  margin-top: 70px;
  margin-bottom: 80px;
}

.post-comments h4 {
  font-weight: 800;
  margin-bottom: 40px;
}

.post-comments img {
  width: 65px;
  height: 65px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.comment-meta {
  margin-bottom: 10px;
}

.comment-meta h5 {
  font-weight: 700;
  font-size: 1.125rem;
  letter-spacing: -0.5px;
  line-height: 1.1;
  margin-bottom: 2px;
}

.comment-date {
  color: #888;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px;
}

.btn-reply {
  margin-left: 3px;
}

.btn-reply a {
  color: #888;
  font-size: 15px;
  font-weight: 400;
}

.btn-reply a i {
  font-size: 13px;
  margin-right: 1px;
}

.btn-reply a:hover {
  color: #222;
}

.post-comments hr {
  margin-top: 35px;
  margin-bottom: 35px;
}

/*------------------------------------------*/
/*    SINGLE POST COMMENT FORM
/*------------------------------------------*/

#leave-comment h4 {
  font-weight: 800;
  margin-bottom: 20px;
}

#leave-comment p.grey-color {
  background-color: #f6f6f6;
  font-size: 0.95rem;
  padding: 13px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.comment-form {
  position: relative;
  margin-top: 50px;
}

.comment-form p {
  color: #333;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 10px;
  padding-left: 5px;
  display: block;
}

.comment-form .form-control {
  height: 54px;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  font-size: 17px;
  font-weight: 300;
  padding: 0 15px;
  margin-bottom: 22px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.comment-form .form-control:focus {
  border-color: #00b2e4;
  outline: 0px none;
  box-shadow: none;
}

.comment-form textarea {
  min-height: 200px;
}
.comment-form textarea.form-control {
  padding: 20px;
}

/*------------------------------------------*/
/*  Comment Form Button
/*------------------------------------------*/

.comment-form .btn {
  margin-top: 0;
}

.comment-form .btn:focus {
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Comment Form Message
/*------------------------------------------*/

.comment-form-msg {
  width: 100% !important;
  display: block;
  text-align: center;
  margin-top: 15px;
}

.comment-form .loading {
  color: #00b2e4;
  font-size: 18px;
  font-family: "Muli", sans-serif;
  font-weight: 600;
}

.error {
  color: #e74c3c;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
}

/* ==========================================================================
  18. FAQs
  =========================================================================== */

#faqs-1 .tabs-nav {
  margin-right: 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid #ddd;
  overflow: hidden !important;
}

#faqs-1 .tabs-1 {
  width: 100%;
  margin: 0;
}

#faqs-1 .tab-content {
  display: none;
}

#faqs-1 .tab-content.current,
#faqs-1 .tab-content.displayed {
  display: inherit;
}

/*------------------------------------------*/
/*  FAQS CATEGORY TITLE
/*------------------------------------------*/

#faqs-1 .category-title h4 {
  line-height: 1;
  margin-bottom: 15px;
}

#faqs-1 .category-title p {
  color: #757575;
  margin-bottom: 30px;
}

/*------------------------------------------*/
/*  FAQS FILTER BUTTONS
/*------------------------------------------*/

#faqs-1 .tabs-1 li {
  position: relative;
  width: 100%;
  background-color: #fff;
  color: #888;
  margin: 0;
  padding: 26px 30px;
  cursor: pointer;
  border-top: 1px dashed #ddd;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

#faqs-1 .tabs-1 li:first-child {
  border-top: none;
}

#faqs-1 .tabs-1 li.current,
#faqs-1 .tabs-1 li.current:hover {
  background-color: #f0f1f3;
  border-top: 1px solid #f0f1f3;
}

#faqs-1 .tab-link img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  text-align: center;
  float: left;
}

#faqs-1 .tab-link.current img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

#faqs-1 .tab-link-txt {
  overflow: hidden;
  padding-left: 15px;
}

#faqs-1 .tabs-1 li h5 {
  font-size: 1.125rem;
  line-height: 1;
  margin-bottom: 8px;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

#faqs-1 .tabs-1 li p {
  color: #888;
  font-size: 0.95rem;
  line-height: 1.3;
  margin-bottom: 0;
  -webkit-transition: all 450ms ease-in-out;
  -moz-transition: all 450ms ease-in-out;
  -o-transition: all 450ms ease-in-out;
  -ms-transition: all 450ms ease-in-out;
  transition: all 450ms ease-in-out;
}

/*------------------------------------------*/
/*  Question
/*------------------------------------------*/

.question {
  margin-bottom: 25px;
}

.question h5 {
  margin-bottom: 15px;
}

.question p,
.question li {
  color: #757575;
}

/* ==========================================================================
  19. NEWSLETTER
  =========================================================================== */

#newsletter-1 .container {
  padding-left: 0;
  padding-right: 0;
}

.newsletter-holder {
  background-image: url(../../public/images/newsletter.jpg);
  padding: 60px 50px 45px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#newsletter-2 {
  background-image: url(../../public/images/newsletter.jpg);
  padding: 60px 50px 45px;
}

/*------------------------------------------*/
/*  NEWSLETTER TYPOGRAPHY
/*------------------------------------------*/

.newsletter-txt h3 {
  margin-bottom: 15px;
}

.newsletter-txt p {
  font-weight: 400;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  NEWSLETTER FORM
/*------------------------------------------*/

.newsletter-section .newsletter-form {
  margin-top: 45px;
}

/*------------------------------------------*/
/*   Newsletter Form Input
/*------------------------------------------*/

.newsletter-section .form-control {
  height: 55px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #ddd;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 0 5px;
  margin-right: 15px;
  box-shadow: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.newsletter-section .input-group > .custom-select:not(:last-child),
.newsletter-section .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/*------------------------------------------*/
/*  Newsletter Form Input Focus
/*------------------------------------------*/

.newsletter-section .form-control:focus {
  outline: 0;
  box-shadow: none;
}

/*------------------------------------------*/
/*  Newsletter Form Input Placeholder
/*------------------------------------------*/

.newsletter-section .form-control::-moz-placeholder {
  color: #eee;
}
.newsletter-section .form-control:-ms-input-placeholder {
  color: #eee;
}
.newsletter-section .form-control::-webkit-input-placeholder {
  color: #eee;
}

/*------------------------------------------*/
/*  Newsletter Form Button
/*------------------------------------------*/

.newsletter-section .btn {
  display: block;
  width: 100% !important;
  height: 55px;
  font-size: 1.05rem;
}

/*------------------------------------------*/
/*  Newsletter Form Notification
/*------------------------------------------*/

.newsletter-section .form-notification {
  font-size: 0.975rem;
  font-weight: 400;
  line-height: 1;
  margin-top: 15px;
  margin-left: 5px;
}

.newsletter-section .form-notification label.valid,
.newsletter-section .form-notification label.error {
  color: #f6412d;
}

.newsletter-section .newsletter-form label.valid {
  color: #48af4b;
}

/* ==========================================================================
  20. CONTACTS
  =========================================================================== */

#contacts-1 .container {
  padding-left: 0;
  padding-right: 0;
}

.contacts-1-holder {
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#contacts-21 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.contacts-2-holder {
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#contacts-2 .col-lg-4 {
  padding-left: 0;
  padding-right: 0;
}

.contacts-txt p {
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 25px;
}

/*------------------------------------------*/
/*  CONTACT BOX
/*------------------------------------------*/

.contact-box {
  text-align: center;
  padding: 60px 20px;
}

.contact-box h5 {
  margin-top: 30px;
  margin-bottom: 10px;
}

.contact-box p {
  color: #757575;
  padding: 0 5%;
  margin-bottom: 20px;
}

/* ==========================================================================
  21. GOOGLE MAP
  =========================================================================== */

.google-map iframe {
  width: 100%;
  height: 450px;
  border: none;
}

/* ==========================================================================
  22. FOOTER
  =========================================================================== */

.footer {
  padding-top: 100px;
  padding-bottom: 30px;
}

#footer-1 .footer-links {
  padding-left: 25%;
}

#footer-2 .footer-links {
  padding-left: 10%;
}

#footer-3 .footer-links {
  padding-left: 5%;
}

/*------------------------------------------*/
/*  FOOTER TYPOGRAPHY
/*------------------------------------------*/

.footer h5 {
  letter-spacing: 0;
  margin-bottom: 30px;
}

.footer-info p {
  margin-top: 25px;
}

.footer-box p {
  font-size: 1.05rem;
  margin-bottom: 4px;
}

.footer-contacts p {
  font-size: 1.05rem;
  margin-bottom: 1px;
}

.footer-contacts .btn {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
}

.bg-lightdark .footer-info p,
.bg-lightdark .footer-links li a,
.bg-lightdark .footer-copyright p,
.bg-lightdark .bottom-footer-list a {
  color: #b3b8c3;
}

.bg-lightdark .footer-links li a:hover,
.bg-lightdark .bottom-footer-list a:hover {
  color: #fff;
}

/*------------------------------------------*/
/*  FOOTER LINKS
/*------------------------------------------*/

.footer-links li {
  width: auto !important;
  display: block !important;
  vertical-align: top;
  clear: none !important;
  font-size: 1.05rem;
  margin: 0 0 9px 0;
  padding: 0;
}

/*------------------------------------------*/
/*  FOOTER SOCIAL LINKS
/*------------------------------------------*/

.foo-socials {
  display: inline-block;
  padding-left: 0;
  margin: 10px auto 0;
}

.bottom-footer .foo-socials {
  margin: 0;
}

.foo-socials li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0;
  padding: 0;
}

.foo-socials a {
  color: #888;
  font-size: 1rem;
  line-height: 30px !important;
  margin-right: 22px;
  display: block;
}

.foo-socials a.ico-facebook:hover {
  color: #3b5998;
}
.foo-socials a.ico-twitter:hover {
  color: #00a9ed;
}
.foo-socials a.ico-instagram:hover {
  color: #e44772;
}
.foo-socials a.ico-dribbble:hover {
  color: #d92d84;
}
.foo-socials a.ico-behance:hover {
  color: #2473f6;
}
.foo-socials a.ico-pinterest:hover {
  color: #ac281a;
}
.foo-socials a.ico-linkedin:hover {
  color: #015886;
}
.foo-socials a.ico-google-plus:hover {
  color: #cd1111;
}
.foo-socials a.ico-youtube:hover {
  color: #cd1b20;
}
.foo-socials a.ico-tumblr:hover {
  color: #3a5976;
}
.foo-socials a.ico-vk:hover {
  color: #3b5998;
}

/*------------------------------------------*/
/*  FOOTER NEWSLETTER FORM
/*------------------------------------------*/

.footer-form .form-control {
  text-align: center;
  height: 48px;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #757575;
  font-size: 15px;
  padding: 0 15px;
  margin-bottom: 10px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  -webkit-transition: all 400ms ease-in-out;
  -moz-transition: all 400ms ease-in-out;
  -o-transition: all 400ms ease-in-out;
  -ms-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}

.bg-lightdark .footer-form .form-control {
  color: #fff;
}

.footer-form .input-group > .custom-select:not(:last-child),
.footer-form .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.bg-lightdark .footer-form .form-control {
  background-color: #262d31;
  border-color: #5b6c75;
}

/*------------------------------------------*/
/*  Contact Form Placeholder
/*------------------------------------------*/

.bg-lightdark .footer-form .form-control::-moz-placeholder {
  color: #b3b8c3;
}
.bg-lightdark .footer-form .form-control:-ms-input-placeholder {
  color: #b3b8c3;
}
.bg-lightdark .footer-form .form-control::-webkit-input-placeholder {
  color: #b3b8c3;
}

/*

/*------------------------------------------*/
/*  Newsletter Form Input Focus
/*------------------------------------------*/

.footer-form .form-control:focus {
  background-color: #fff !important;
  border: 1px solid #ccc;
  outline: 0;
  box-shadow: none;
}

.bg-lightdark .footer-form .form-control:focus {
  background-color: #384349 !important;
  border: 1px solid #323c41;
}

.footer-form input:-internal-autofill-selected {
  background-color: #fff !important;
}

/*------------------------------------------*/
/*  Newsletter Form Button
/*------------------------------------------*/

.footer-form .input-group-btn {
  display: block;
  width: 100% !important;
}

.footer-form .btn {
  display: block;
  width: 100% !important;
  height: 48px;
  font-size: 15px;
  padding: 0 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

/*------------------------------------------*/
/*  Newsletter Form Notification
/*------------------------------------------*/

.footer-form .form-notification {
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1;
  margin-top: 15px;
  margin-left: 5px;
}

/*------------------------------------------*/
/*  BOTTOM FOOTER
/*------------------------------------------*/

.bottom-footer {
  border-top: 1px solid #eee;
  margin-top: 20px;
  padding-top: 35px;
}

.bg-lightdark .bottom-footer {
  border-top: 1px solid #414e55;
}

.bottom-footer p {
  margin-bottom: 0;
}

.bottom-footer p.p-sm {
  display: inline-block;
  font-size: 0.925rem;
  line-height: 1;
  margin-left: 17px;
  margin-bottom: 0;
}

.bg-dark .bottom-footer p.p-sm {
  color: #ddd;
}

/*------------------------------------------*/
/*  Bottom Footer List
/*------------------------------------------*/

.bottom-footer-list li {
  font-size: 1rem;
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  margin: 0 9px 5px 0;
  padding: 0;
}

.bottom-footer-list li a {
  font-weight: 600;
}

.bottom-footer-list p:after {
  content: "|";
  padding-left: 11px;
  position: relative;
  top: -1px;
}

.bottom-footer-list p.last-li:after {
  content: " ";
  padding-left: 0;
}

/* ==========================================================================
  23. INNER PAGE WRAPPER
  =========================================================================== */

.inner-page-wrapper {
  margin-top: 90px;
}

/* ==========================================================================
  24. BREADCRUMB
  =========================================================================== */

.breadcrumb {
  padding: 16px 5px 14px;
  background-color: transparent;
  margin-bottom: 0;
  border-radius: 0;
  border-bottom: 1px solid #efefef;
}

/*------------------------------------------*/
/*  BREADCRUMB TYPOGRAPHY
/*------------------------------------------*/

.breadcrumb-item a,
.breadcrumb-item.active {
  color: #343536;
  font-size: 0.925rem;
  font-weight: 600;
}

.breadcrumb-item.active {
  color: #999;
  font-weight: 500;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-size: 1.05rem;
  display: inline-block;
  padding-right: 9px;
  margin-top: -1px;
  content: "\00bb";
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 11px;
}

/* ==========================================================================
  25. INNER PAGE HERO
  =========================================================================== */

.page-hero-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.page-hero-section h4,
.page-hero-section h3 {
  line-height: 1;
  margin-bottom: 15px;
}

.page-hero-section p {
  font-size: 1.05rem;
  line-height: 18px;
  font-weight: 300;
  margin-bottom: 13px;
}

.page-hero-section p span {
  font-weight: 700;
}

/*------------------------------------------*/
/*  PAGE HERO SHARE ICONS
/*-----------------------------------------*/

.page-hero-section .share-social-icons {
  display: inline-block;
  padding-left: 0;
}

.page-hero-section .share-social-icons li {
  width: auto !important;
  display: inline-block !important;
  vertical-align: top;
  clear: none !important;
  padding: 0;
}

.page-hero-section .share-social-icons li p {
  font-size: 1.05rem;
  line-height: 18px;
  font-weight: 300;
  margin-right: 12px;
  margin-bottom: 3px;
}

.page-hero-section .share-social-icons li p span {
  font-weight: 700;
}

.page-hero-section a.share-ico {
  display: block;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  margin-right: 12px;
  opacity: 0.85;
}

.page-hero-section a.share-ico i {
  position: relative;
  font-size: 16px;
  line-height: 1;
  margin-right: 3px;
  top: 1px;
}

.page-hero-section a.share-ico:hover {
  opacity: 1;
}

/* ==========================================================================
  26. SIDEBAR
  =========================================================================== */

/*------------------------------------------*/
/*   SIDEBAR SEARCH FORM
/*------------------------------------------*/

#search-field .form-control {
  background-color: #f0f0f0;
  height: 54px;
  font-size: 17px;
  padding-left: 20px;
  border: none;
  -webkit-border-radius: 6px 0 0 6px;
  -moz-border-radius: 6px 0 0 6px;
  border-radius: 6px 0 0 6px;
}

#search-field .btn {
  background-color: #f0f0f0;
  color: #777;
  border: none;
  height: 54px;
  padding: 0 18px;
  margin-top: 0;
  -webkit-box-shadow: 0 0 0;
  -moz-box-shadow: 0 0 0;
  box-shadow: 0 0 0;
  -webkit-border-radius: 0px 6px 6px 0;
  -moz-border-radius: 0px 6px 6px 0;
  border-radius: 0px 6px 6px 0;
}

#search-field .form-control:focus {
  border-color: #999;
  outline: 0px none;
  box-shadow: none;
}

/*------------------------------------------*/
/*   SIDEBAR BLOG CATEGORIES
/*------------------------------------------*/

ul.blog-category-list > li {
  padding: 15px 0;
  border-bottom: 1px dashed #c0c0c0;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

ul.blog-category-list > li:first-child {
  padding: 0 0 15px;
}

ul.blog-category-list > li:last-child {
  padding: 15px 0 0;
  border-bottom: none;
}

ul.blog-category-list > li a {
  color: #0c849d;
  font-size: 1.05rem;
  font-weight: 400;
}

ul.blog-category-list > li span {
  color: #096376;
  font-weight: 700;
}

ul.blog-category-list li:hover {
  padding-left: 8px;
}

/* ==========================================================================
  27. COURSE DETAILS
  =========================================================================== */

/*------------------------------------------*/
/*   COURSE DESCRIPTION
/*------------------------------------------*/

.course-txt h3 {
  font-weight: 800;
  padding-right: 10%;
  margin-bottom: 20px;
}

/*------------------------------------------*/
/*   WHAT YOU LEARN
/*------------------------------------------*/

.what-you-learn {
  background-color: #f6f6f6;
  border: 1px solid #f0f0f0;
  margin: 45px 0;
  padding: 30px 25px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.what-you-learn h5 {
  line-height: 1;
  margin-bottom: 20px;
}

.what-you-learn .txt-list li {
  font-size: 0.975rem;
}

/*------------------------------------------*/
/*   COURSE BLOCK
/*------------------------------------------*/

.cd-block {
  margin-top: 30px;
  margin-bottom: 40px;
}

.cs-rating.cd-block {
  margin-top: 60px;
}

.cd-block h5 {
  line-height: 1;
  margin-bottom: 20px;
}

p.course-short-data {
  color: #888;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 2px;
}

.cd-block .txt-list li {
  margin-bottom: 4px;
}

/*------------------------------------------*/
/*  COURSE ACCORDION  
/*------------------------------------------*/

#accordion .card {
  background-color: transparent;
  border: 1px solid #eee;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 5px;
}

/*------------------------------------------*/
/*  Card Header
/*------------------------------------------*/

.cs-content .card-header {
  padding: 15px 45px;
  background-color: #f6f6f6;
  border: 1px solid #f0f0f0;
  position: relative;
}

.cs-content #accordion [data-toggle="collapse"].collapsed:after,
.cs-content #accordion [data-toggle="collapse"]:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f106";
  position: absolute;
  font-size: 1.05rem;
  top: 17px;
  left: 20px;
}

.cs-content #accordion [data-toggle="collapse"].collapsed:after {
  content: "\f107";
}

#accordion .card-header h5 {
  line-height: 1;
  letter-spacing: -0.5px;
  margin-bottom: 0;
}

.hdr-data {
  position: absolute;
  top: 17px;
  right: 25px;
}

.hdr-data p {
  color: #888;
  font-size: 0.975rem;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 0;
}

/*------------------------------------------*/
/*  Card Body
/*------------------------------------------*/

#accordion .card-body {
  padding: 20px 25px 20px;
}

.card-body p {
  font-size: 0.95rem;
  margin-bottom: 10px;
}

.card-body p.cb-video {
  margin-bottom: 2px;
}

#accordion .card-body .txt-list li {
  font-size: 0.95rem;
  margin-bottom: 4px;
}

.card-body a {
  color: #656667;
}

.card-body a i {
  font-size: 0.9rem;
  margin-right: 2px;
  position: relative;
  top: -0.5px;
}

/*------------------------------------------*/
/*  COURSE RATING
/*------------------------------------------*/

.cs-rating-data {
  margin-top: 20px;
  margin-bottom: 20px;
}

.ratingtext-right {
  width: 12%;
  display: inline-block;
  float: left;
  margin-right: 10px;
}

.ratingtext-left {
  width: 12%;
  display: inline-block;
  float: right;
}

.ratingtext-right p,
.ratingtext-left p {
  font-size: 0.95rem;
  line-height: 1;
  font-weight: 700;
  position: relative;
  top: 5px;
  margin-bottom: 0;
}

.ratingtext-left p {
  font-weight: 400;
  top: 0;
}

/*------------------------------------------*/
/*  Rating Progress Bar
/*------------------------------------------*/

.barWrapper {
  margin-bottom: 7px;
}

.progress-wrapper {
  width: 70%;
  display: inline-block;
  float: left;
  height: 7px;
  background-color: #f0f0f0;
  position: relative;
  margin-right: 30px;
  top: 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.progress-bar {
  background-color: #999;
  height: 7px;
}

/*------------------------------------------*/
/*   COURSE DATA
/*------------------------------------------*/

.course-data {
  background-color: #fff;
  border: 1px solid #eee;
  padding: 8px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#courses-2 .course-data {
  border: none;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  -webkit-box-shadow: 0 0;
  -moz-box-shadow: 0 0;
  box-shadow: 0 0;
}

/*------------------------------------------*/
/*   COURSE DATA PRICE
/*------------------------------------------*/

.course-data-price {
  display: block;
  font-size: 1.65rem;
  font-family: "Muli", sans-serif;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 8%;
}

.course-data-price .old-price {
  color: #999;
  font-size: 1.25rem;
  font-weight: 300;
  text-decoration: line-through;
  margin-left: 5px;
  margin-right: 5px;
}

.course-data-price p {
  color: #d63e57;
  font-size: 0.925rem;
  font-weight: 600;
  margin-top: 2px;
}

/*------------------------------------------*/
/*   COURSE DATA LINKS
/*------------------------------------------*/

.course-data-links {
  padding: 0 3%;
}

.course-data-links .btn {
  width: 100%;
  display: block;
  margin-bottom: 10px;
}

/*------------------------------------------*/
/*   COURSE DATA LIST
/*------------------------------------------*/

.course-data-list {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 0 8%;
}

.course-data-list span {
  display: block;
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 12px;
}

.course-data-list p {
  color: #656565;
  font-size: 0.9rem;
  line-height: 1;
  margin-bottom: 8px;
}

.course-data-list p i {
  font-size: 0.8rem;
  margin-right: 4px;
}

/*------------------------------------------*/
/*   COURSE DOWNLOAD FILE
/*------------------------------------------*/

p.download-file {
  font-size: 0.95rem;
  font-weight: 400;
}

p.download-file i {
  font-size: 0.875rem;
  margin-right: 3px;
}

/* ==========================================================================
  28. PAGE PAGINATION
  =========================================================================== */

.page-pagination {
  padding-bottom: 100px;
}

.page-link {
  color: #666;
  font-weight: 600;
  padding: 8px 15px;
  margin: 0 3px;
  background-color: transparent;
  border-color: transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.page-item:first-child .page-link {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.page-item.disabled .page-link {
  color: #aaa;
  background-color: transparent;
  border-color: transparent;
}

.next-page.page-link1 {
  margin-left: 30px;
}

.page-link:hover,
.page-item.active .page-link {
  background-color: #e5e5e5;
  color: #333;
  border-color: #e5e5e5;
  box-shadow: 0 0 0 1px rgba(56, 60, 67, 0.05),
    0 1px 3px 0 rgba(56, 60, 67, 0.15);
}

.page-link:focus {
  background-color: transparent;
  color: #666;
  border-color: transparent;
  box-shadow: 0 0;
}

/* ==========================================================================
  29. SCROLL TO TOP
  =========================================================================== */

#scrollUp {
  display: none;
  width: 40px;
  height: 45px;
  position: fixed;
  bottom: 20px;
  right: 40px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-image: url(../../public/images/back-to-top.png);
  background-repeat: no-repeat;
  background-position: 50% 48%;
  background-color: rgba(35, 35, 35, 0.65);
  -webkit-transition: all 250ms linear;
  -moz-transition: all 250ms linear;
  transition: all 250ms linear;
}

#scrollUp:hover {
  background-color: #151515;
}

nav a#pull {
  display: none;
}
/* new cssss */
.w-container{
  background-image: linear-gradient(90deg, #1C1330 0%, #172C35 100%);
}
.shrink-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color:red;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
  width: 80%;
  height: 20%;
}

.shrink-button:hover {
  transform: scale(0.5);
}