@import "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap";
@import "https://fonts.googleapis.com/css?family=Muli:400,600,700,800,900&display=swap";
@import "https://use.fontawesome.com/releases/v5.11.0/css/all.css";
@import "./bootstrap.min.css";
@import "./flaticon.css";
@import "./menu.css";
@import "./dropdown-effects/fade-down.css";
@import "./magnific-popup.css";
@import "./flexslider.css";
@import "./owl.carousel.min.css";
@import "./owl.theme.default.min.css";
@import "./animate.css";
@import "./style.css";
@import "./responsive.css";

.owl-carousel {
  display: block !important;
}
