/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.reg-modal-content {
  margin-top: 25px;
  width: 40%;
  height: 82%;
  background: white;
  padding: 10px 35px;
  border-radius: 4px;
  position: fixed;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
@media (min-width: 480px) and (max-width: 767.95px) {
  #register-form {
    /* padding: 40px 30px 25px !important;
        margin: 0 30px 40px !important; */
    padding: 40px 30px 25px;
    margin: 0 30px 40px;
  }
  .reg-modal-content {
    width: 72.6% !important;
    height: 83% !important;
  }
}

@media (min-width: 768px) and (max-width: 991.95px) {
#register-form { padding: 30px 15px 15px; margin: 0 0 40px;height: 57%;}

.reg-modal-content {
  width: 65.6%;
  height: 74%;
}}
@media (min-width: 992px) and (max-width: 1199.95px) {
  #register-form { padding: 30px 15px 15px; margin: 0 0 40px;height: 57%;}

.reg-modal-content {
  width: 65.6%;
  height: 43%;
}}
@media (min-width: 321px) and (max-width: 479.95px) {
  .reg-modal-content {
    width:80%;
    height: 74%;
  }}