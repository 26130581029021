/*
 * jQuery FlexSlider v2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */

/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
}
.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* FlexSlider Necessary Styles
*********************************/
.flexslider {
  margin: 0;
  padding: 0;
}
.flexslider .slides > li,
.content_slider .slides > li,
.hero-slider .slides > li,
.blog-img-slider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
} /* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
  width: 100%;
  display: block;
}
.flex-pauseplay span {
  text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .slides {
  display: block;
}
* html .slides {
  height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block;
}

/* FlexSlider Default Theme
*********************************/
.flexslider,
.flexslider-thumbs {
  margin: 0;
  background: transparent;
  position: relative;
  zoom: 1;
}
.content_slider,
.hero_slider,
.blog-img-slider {
  position: relative;
  padding-bottom: 0;
}
.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  transition: all 1s ease;
}
.loading .flex-viewport {
  max-height: 300px;
}
.flexslider .slides {
  zoom: 1;
}

.carousel li {
  margin-right: 5px;
}

/* Direction Nav */
.flex-direction-nav {
  *height: 0;
}
.flex-direction-nav a {
  width: 25px;
  height: 50px;
  margin: -20px 0 0;
  display: block;
  /* background: url(../img/icons/bg_direction_nav.png) no-repeat 0 0; */
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  text-indent: -9999px;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
}
.flex-direction-nav .flex-next {
  background-position: 100% 0;
  right: -10px;
}
.flex-direction-nav .flex-prev {
  left: -10px;
}
.content_slider:hover .flex-next,
.hero_slider:hover .flex-next,
.blog-img-slider:hover .flex-next {
  opacity: 0.9;
  right: 5px;
}
.content_slider:hover .flex-prev,
.hero_slider:hover .flex-prev,
.blog-img-slider:hover .flex-prev {
  opacity: 0.9;
  left: 5px;
}
.content_slider:hover .flex-next:hover,
.hero_slider:hover .flex-next:hover,
.content_slider:hover .flex-prev:hover,
.hero_slider:hover .flex-prev:hover,
.blog-img-slider:hover .flex-next:hover,
.blog-img-slider:hover .flex-prev:hover {
  opacity: 0.85;
}

.flex-direction-nav .flex-disabled {
  opacity: 0.3 !important;
  filter: alpha(opacity=30);
  cursor: default;
}

/* Control Nav */
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: 5px;
  text-align: center;
}
.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.flex-control-paging li a {
  width: 10px;
  height: 10px;
  display: block;
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
}
.flex-control-paging li a.flex-active {
  cursor: default;
}

.flex-control-thumbs {
  margin: 4px 0 0;
  position: static;
  overflow: hidden;
}
.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}
.flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: 0.65;
  cursor: pointer;
}
.flex-control-thumbs img:hover {
  opacity: 1;
}
.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}
