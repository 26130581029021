	/*
  	Flaticon icon font: Flaticon
  	Creation date: 19/03/2019 07:49
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
  margin-left: 0;
}

.flaticon-001-book:before { content: "\f100"; }
.flaticon-002-book-1:before { content: "\f101"; }
.flaticon-003-book-2:before { content: "\f102"; }
.flaticon-004-computer:before { content: "\f103"; }
.flaticon-005-computer-1:before { content: "\f104"; }
.flaticon-006-diploma:before { content: "\f105"; }
.flaticon-007-diploma-1:before { content: "\f106"; }
.flaticon-008-elearning:before { content: "\f107"; }
.flaticon-009-elearning-1:before { content: "\f108"; }
.flaticon-010-elearning-2:before { content: "\f109"; }
.flaticon-011-elearning-3:before { content: "\f10a"; }
.flaticon-012-elearning-4:before { content: "\f10b"; }
.flaticon-013-elearning-5:before { content: "\f10c"; }
.flaticon-014-elearning-6:before { content: "\f10d"; }
.flaticon-015-elearning-7:before { content: "\f10e"; }
.flaticon-016-elearning-8:before { content: "\f10f"; }
.flaticon-017-elearning-9:before { content: "\f110"; }
.flaticon-018-graduate:before { content: "\f111"; }
.flaticon-019-graduate-1:before { content: "\f112"; }
.flaticon-020-graduate-2:before { content: "\f113"; }
.flaticon-021-idea:before { content: "\f114"; }
.flaticon-022-jigsaw:before { content: "\f115"; }
.flaticon-023-laptop:before { content: "\f116"; }
.flaticon-024-laptop-1:before { content: "\f117"; }
.flaticon-025-laptop-2:before { content: "\f118"; }
.flaticon-026-learning:before { content: "\f119"; }
.flaticon-027-learn:before { content: "\f11a"; }
.flaticon-028-learning-1:before { content: "\f11b"; }
.flaticon-029-mobile:before { content: "\f11c"; }
.flaticon-030-mobile-1:before { content: "\f11d"; }
.flaticon-031-select:before { content: "\f11e"; }
.flaticon-032-tablet:before { content: "\f11f"; }
.flaticon-033-teacher:before { content: "\f120"; }
.flaticon-034-tutorial:before { content: "\f121"; }
.flaticon-035-video:before { content: "\f122"; }
.flaticon-036-video-1:before { content: "\f123"; }